import { React, useState, useEffect } from "react";
import FieldBuilder from "../FieldBuilder/FieldBuilder";
import classes from "./SectionFieldsBuilder.module.css";
import FieldsSelector from "../FieldsSelector/FieldsSelector";
import SelectionModalComponent from "../SelectionModal/SelectionModal";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Tooltip, Typography } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import ReorderIcon from '@mui/icons-material/Reorder';
import BackupIcon from '@mui/icons-material/Backup';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const SectionFieldsBuilder = (props) => {

    const initialFieldState = {
        name: '',
        label: '',
        type: 'text',
        dataType: 'string',
        length: 0,
        required: false,
        wrapping: false,
        visible: false
    };

    const [fieldsArray, setFieldsArray] = useState([]);
    const [fieldsSelectorOpen, setFieldsSelectorOpen] = useState(false);

    const closeModal = () => {
        setFieldsSelectorOpen(false);
    };

    useEffect(() => {
        console.log('Props have changed SectionFieldsBuilder: ', props.fieldsArray);
        setFieldsArray(props.fieldsArray);
    }, [props.fieldsArray]);

    const updateFieldsArray = (field, index) => {
        if (field === undefined) {
            props.removeFieldFromFieldsArray(fieldsArray, index);
        } else {
            fieldsArray[index] = { ...field };
            props.updateSectionFieldsArray(fieldsArray);
        }
        console.log(" SectionFieldsBuilder fieldsArray: ", fieldsArray);
    };

    const handleAddField = () => {
        fieldsArray.push(initialFieldState);
        const newFieldsArray = Array.from(fieldsArray);
        setFieldsArray(newFieldsArray);
        console.log("fieldsArray: ", fieldsArray);
    };

    const handleSubmitSection = async () => {
        props.updateSectionFieldsArray(fieldsArray);
        await props.handleSubmitSectionToBackend(fieldsArray);
    };

    const getAllSectionFields = () => {
        return fieldsArray;
    };

    const addOptionsIntoField = (field) => {
        console.log("HERE INSIDE ADD OPTIONS:            ", field);
        if (field === undefined) {
            handleAddField();
        }
        else {
            console.log("fields Array before: ", fieldsArray);
            console.log("field from modal: ", field)
            fieldsArray.push(field);
            const newFieldsArray = Array.from(fieldsArray);
            setFieldsArray(newFieldsArray);
            console.log("updated fields array: ", fieldsArray);
        };
    };

    const handleMoveFieldUp = (index) => {
        console.log("index to move up: ", index);
        if (index > 0) {
            const newFieldsArray = [...fieldsArray];
            [newFieldsArray[index - 1], newFieldsArray[index]] = [newFieldsArray[index], newFieldsArray[index - 1]];
            setFieldsArray(newFieldsArray);
        }
    };

    const handleMoveFieldDown = (index) => {
        console.log("index to move down: ", index);
        if (index < fieldsArray.length - 1) {
            const newFieldsArray = [...fieldsArray];
            [newFieldsArray[index + 1], newFieldsArray[index]] = [newFieldsArray[index], newFieldsArray[index + 1]];
            setFieldsArray(newFieldsArray);
        }
    };

    const handleSelectField = () => {
        setFieldsSelectorOpen(true);
    };

    const handleRemoveSection = () => {
        props.handleRemoveSection();
    };


    return (
        <div>
            <div>
                {fieldsArray.length > 0 && (fieldsArray.map((field, index) => (
                    <div key={index} className={classes.fieldContainer}>
                            <div className={classes.ButtonContainerDrag}>
                            <Tooltip title="Move Up">
                            <Button
                                onClick={()=> handleMoveFieldUp(index)}
                                title="Move this field up"
                                // variant="contained"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: 0,
                                    background: 'transparent',
                                    color: 'var(--app-color)',
                                    cursor: 'pointer',
                                    borderRadius: '10px',
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                        color: 'var(--button-hover-colo)',
                                    },
                                    height: '30px'
                                }}
                            >
                            <KeyboardDoubleArrowUpIcon sx={{ fontSize: 25, marginTop: 0, marginBottom: '0px', color: 'var(--app-color)' }}/>
                            </Button>
                            </Tooltip>
                            <Tooltip title="Move Down">
                            <Button
                                onClick={()=>handleMoveFieldDown(index)}
                                // variant="contained"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: 0,
                                    background: 'transparent',
                                    color: 'var(--app-color)',
                                    cursor: 'pointer',
                                    borderRadius: '10px',
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                        color: 'var(--button-hover-colo)',
                                    },
                                    height: '30px'
                                }}
                            ><KeyboardDoubleArrowDownIcon sx={{ fontSize: 25, marginTop: 0, marginBottom: '0px', color: 'var(--app-color)' }}/>
                            </Button>
                            </Tooltip>
                        </div>
                        <FieldBuilder
                            key={index}
                            indexFieldArray={index}
                            field={field}
                            updateFieldsArray={updateFieldsArray}
                            getAllSectionFields={getAllSectionFields}
                            isForm={true}
                        />

                    </div>
                )))}
            </div>
            {/* {!props.isOnlyReadAcess && <div className={classes.ButtonContainer}>
                <SelectionModalComponent
                    type={'Fields'}
                    Builder={FieldBuilder}
                    Selector={FieldsSelector}
                    addOptionsIntoField={addOptionsIntoField}
                ></SelectionModalComponent>
            </div>} */}
            <div className={classes.FieldsButtonContainer}>  
            <Button
            onClick={handleRemoveSection}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 0,
              background: 'transparent',
              color: 'var(--app-color)',
              cursor: 'pointer',
              borderRadius: '10px',
              ':hover': {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              height: '58px'
            }}
          >
            <RemoveCircleIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'red', padding: 0 }} />
            <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Remove</Typography>
          </Button>
            <Button
              onClick={handleSubmitSection}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
                background: 'transparent',
                color: 'var(--icon-button-color-green)',
                cursor: 'pointer',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: 'var(--icon-button-color-green)',
                },
                height: '58px'
              }}
            >
              <BackupIcon sx={{ fontSize: 32, marginTop: 1, marginBottom: '5px', color: 'var(--icon-button-color-green)', padding: 0 }} />
              <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Save</Typography>
            </Button>
            <Button
              onClick={handleAddField}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
                background: 'transparent',
                color: 'var(--icon-button-color-green)',
                cursor: 'pointer',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: 'var(--icon-button-color-green)',
                },
                height: '58px'
              }}
            >
              <AddBoxIcon sx={{ fontSize: 32, marginTop: 1, marginBottom: '5px', color: 'var(--icon-button-color-green)', padding: 0 }} />
              <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Add Field</Typography>
            </Button> 
            <Button
              onClick={handleSelectField}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
                background: 'transparent',
                color: 'var(--icon-button-color-green)',
                cursor: 'pointer',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: 'var(--icon-button-color-green)',
                },
                height: '58px'
              }}
            >
              <ReorderIcon sx={{ fontSize: 32, marginTop: 1, marginBottom: '5px', color: 'var(--icon-button-color-green)', padding: 0 }} />
              <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Select Field</Typography>
            </Button>
            </div>
            <div className={classes.ButtonContainer}>
            {/* <button className={classes.Button} onClick={handleAddField}>Add  Field</button>
                <button className={classes.Button} onClick={handleSelectField}>Select Field</button> */}
                {/* <button className={classes.Button} onClick={handleSubmitSection}>Save Section</button> */}
            </div>
            <FieldsSelector
                isOpen={fieldsSelectorOpen}
                handleClose={closeModal}
                submitField={addOptionsIntoField}
            />
        </div>
    )
}

export default SectionFieldsBuilder;
