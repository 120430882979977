import React, { useEffect, useRef, useState } from 'react';
import './AutoResizeTextarea.css'; // Ensure you have the CSS for textarea

const AutoResizeTextarea = ({ initialContent, disabled, minHeight = 50 }) => {
    const textareaRef = useRef(null);
    const [content, setContent] = useState(initialContent);

    useEffect(() => {
        console.log("initialContent: ", initialContent);
        setContent(initialContent);
    }, [initialContent]);

    useEffect(() => {
        const textarea = textareaRef.current;
        if (textarea) {
            // Reset height to auto to get the correct scrollHeight
            textarea.style.height = 'auto';
            // Calculate the new height based on the content
            const newHeight = Math.max(textarea.scrollHeight, minHeight);
            // Set height to the new height
            textarea.style.height = `${newHeight}px`;
        }
    }, [content, minHeight]); // Re-run the effect when content or minHeight changes

    const handleContentChange = (e) => {
        setContent(e.target.value);
    };

    return (
        <textarea
            ref={textareaRef}
            value={content}
            onChange={handleContentChange}
            className="auto-resize-textarea"
            style={{ minHeight: `${minHeight}px` }}
            disabled={disabled}
        />
    );
};

export default AutoResizeTextarea;