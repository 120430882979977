import React, { useEffect } from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SectionBuilder from '../FormBuilder/SectionBuilder/SectionBuilder';

const SectionsModal = ({ open, handleClose, section }) => {

  useEffect(()=>{
    console.log('section received: ', section);
  }, [section])

  // Customize your modal content here
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx = {{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box sx={{
        width: '90%',
        height: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'auto', 
        overflowX: 'auto',
        justifyContent: 'center'
      }}>
        {/* <Typography variant="h6" id="modal-title">
          Modal Title
        </Typography> */}
        <Typography variant="body1" id="modal-description" sx={{ mt: 2 }}>
          {(Object.keys(section).length===undefined) ? <SectionBuilder section = {{}}/> : <SectionBuilder section = {section} handleClose={handleClose}/>}
        </Typography>
        {/* <Button onClick={handleClose} sx={{ mt: 2 }}>Close</Button> */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: 'text.secondary',
            backgroundColor: '#2b4252',
            color: 'white',
            ":hover": {
                backgroundColor: '#2b4252',
                color: 'white',
            }
          }}
        >
          <CloseIcon />
        </IconButton>

      </Box>
    </Modal>
  );
}

export default SectionsModal;
