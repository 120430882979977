import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, List, ListItem, ListItemText, Typography, Container, Toolbar, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ChecklistIcon from '@mui/icons-material/Checklist';
import apiGatewayService from '../../common/services/apiGatewayService';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import CircularLoader from '../Loader/Loader';
import FieldOptionsBuilder from '../FormBuilder/FieldOptionsBuilder/FieldOptionsBuilder';
import { OptionsJSONTemplate } from '../../common/configurations/json-configurations';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ImportOptionsModal from '../ImportOptionsModal/ImportOptionsModal';

const Options = () => {
  const [options, setOptions] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewIndex, setIsViewIndex] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [importedOptions, setImportedOptions] = useState(null);
  const [isImportedOption, setIsImportedOption] = useState(false);
  const [currentOption, setCurrentOption] = useState(null);

  useEffect(() => {
    const getOptions = async (user) => {
      try {
        const response = await apiGatewayService.getOptionsData();
        const dataWithUpdatedKeyAndContent = response.data;
        setOptions(dataWithUpdatedKeyAndContent);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
      setIsLoading(false);
    };
    const user = localStorageService.getObject('user');
    setCognitoUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    getOptions(user);
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = async () => {
    setOpen(false);
    await getAllOptions();
    setAllOptionsFalse();
  };

  const getAllOptions = async () => {
    try {
      const response = await apiGatewayService.getOptionsData();
      const dataWithUpdatedKeyAndContent = response.data;
      setOptions(dataWithUpdatedKeyAndContent);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  const setAllOptionsFalse = () => {
    setIsAddNew(false);
    setIsImport(false);
    setIsEdit(false);
    setIsViewIndex(null);
    setImportModalOpen(false);
    setIsImportedOption(false);
  };

  const handleAddNew = () => {
    setAllOptionsFalse();
    setIsAddNew(true);
    setOpen(true);
  };

  const handleImport = () => {
    setAllOptionsFalse();
    setIsImport(true);
    setImportModalOpen(true);
  };

  const handleUpload = async (jsonContent) => {
    const isValid = validateJson(jsonContent);
    if (isValid) {
      setAllOptionsFalse();
      setImportedOptions({
        title: '',
        values: jsonContent
      });
      console.log("Imported Options Are: ", jsonContent);
      setImportModalOpen(false);
      setTimeout(setIsImportedOption(true), 300);
    } else {
      alert("Invalid JSON file. Please ensure the file follows the correct template.");
    }
  };

  const validateJson = (jsonContent) => {
    return utilityFunctions.validateUploadedOptionsJson(OptionsJSONTemplate, jsonContent);
  };

  const handleEdit = async (index) => {
    setAllOptionsFalse();
    setIsLoading(true);
    await getOptionDataById(options[index].optionId);
    setTimeout(() => {
      setIsEdit(true);
      setIsViewIndex(index);
      setIsLoading(false);
    }, 500);
  };

  const handleDelete = async (index) => {
    try {
      const postData = {
        optionNames: [options[index].optionId],
      };
      await apiGatewayService.deleteOptionsData(postData);
      setAllOptionsFalse();
      const optionsNew = options.filter((_, index2) => index2 !== index);
      setOptions(optionsNew);
    } catch (error) {
      console.error("Error:", error);
      alert('Option cannot be deleted');
    }
  };

  const getOptionDataById = async (optionId) => {
    try {
      const response = await apiGatewayService.getOptionsDataById(decodeURIComponent(optionId));
      setCurrentOption({
        title: optionId,
        values: response.data
      });
    } catch (error) {
      console.log("Error in getting option by Id: ", error);
    }
  };

  return (
    <Container sx={{ padding: '0px' }}>
      <Toolbar sx={{ backgroundColor: 'var(--app-color-secondary)', borderRadius: "10px", padding: '0px', color: 'white' }}>
        <IconButton sx={{ marginLeft: '-20px' }} edge="start" color="inherit" aria-label="menu">
          <ChecklistIcon />
        </IconButton>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Options List
        </Typography>
        {(isEdit || isAddNew) && 
          <Button
            sx={{
              width: '100px',
              marginRight: '-10px',
              ":hover": {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: 'grey',
              },
            }}
            color="inherit"
            startIcon={<KeyboardBackspaceIcon />}
            onClick={handleClose}
            disabled={isOnlyReadAccess}
          >
            Back
          </Button>
        }
        {(!isEdit && !isAddNew) &&
          <div>
            <Button
              sx={{
                marginRight: '10px',
                ":hover": {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#e0e0e0',
                  color: 'grey',
                },
              }}
              color="inherit"
              startIcon={<AddIcon />}
              onClick={handleAddNew}
              disabled={isOnlyReadAccess}
            >
              Add New
            </Button>
            <Button
              sx={{
                marginRight: '-10px',
                ":hover": {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#e0e0e0',
                  color: 'grey',
                },
              }}
              color="inherit"
              startIcon={<ImportExportIcon />}
              onClick={handleImport}
              disabled={isOnlyReadAccess}
            >
              Import
            </Button>
          </div>
        }
      </Toolbar>

      {isLoading && <CircularLoader />}
      {(!isLoading && !isImportedOption && !isEdit && !isAddNew) &&
      <List sx={{ flexGrow: 1, p: 0 }}>
        {options.map((option, index) => (
          <ListItem key={index} sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, pt: '6px', pb: '6px', pl: 0, pr: 0 }}>
            <Paper sx={{ flexGrow: 1, p: 1 }}>
              <ListItemText primary={option.optionId} />
            </Paper>
            <IconButton aria-label="view" onClick={() => handleEdit(index)} disabled={isOnlyReadAccess}>
              <VisibilityIcon />
            </IconButton>
            <IconButton sx={{ color: '#2b4252' }} aria-label="edit" onClick={() => handleEdit(index)}>
              <EditIcon />
            </IconButton>
            <IconButton sx={{ color: '#CC3824' }} aria-label="delete" onClick={() => handleDelete(index)} disabled={isOnlyReadAccess}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    }


      {isAddNew && <FieldOptionsBuilder />}
      {isEdit && <FieldOptionsBuilder option={currentOption} handleClose={handleClose} />}
      {isImportedOption && <FieldOptionsBuilder option={importedOptions} handleClose={handleClose} />}
      <ImportOptionsModal
        open={importModalOpen}
        handleClose={() => setImportModalOpen(false)}
        handleUpload={handleUpload}
        jsonTemplate={OptionsJSONTemplate}
      />
    </Container>
  );
};

export default Options;