import React, { useState, useEffect } from 'react';
import classes from './FieldBuilder.module.css';
import ModalComponent from '../OptionsModal/OptionsModal';
import FieldOptionsBuilder from '../FieldOptionsBuilder/FieldOptionsBuilder';
import FieldOptionsSelector from '../FieldOptionsSelector/FieldOptionsSelector';
import SelectionModalComponent from '../SelectionModal/SelectionModal';
import apiGatewayService from '../../../common/services/apiGatewayService';
import FieldsDependantSelector from '../FieldsDependantSelector/FieldsDependantSelector';
import validationService from '../../../common/validationService.service';
import localStorageService from '../../../common/services/localStorage.service';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, IconButton, InputLabel, TextField, Checkbox, 
 FormControlLabel, FormControl, Select, MenuItem, Typography } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BackupIcon from '@mui/icons-material/Backup';
import LowPriorityIcon from '@mui/icons-material/LowPriority'; 
import utilityFunctions from '../../../common/services/utilityFunctions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ListIcon from '@mui/icons-material/List';
import StyleIcon from '@mui/icons-material/Style';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TableColumns from '../../TableColumns/TableColumns';

const initialFieldState = {
  name: '',
  label: '',
  type: 'text',
  dataType: 'string',
  length: 50,
  required: false,
  wrapping: false,
  visible: false,
  placeholdertext: '',
  options: '',
  dependant: ''
};

const FieldBuilder = (props) => {
  useEffect(() => {
    console.log("Props field builder: ",props);
    if(Object.keys(props.field).length !== 0){
      if(props.field.api){
        props.field.options = getOptionName(props.field.api);
        setOptionsEnabled(true);
      }
      setField(props.field);
      handleDropdownAndSelectedOnInput(props.field);
    } else {
      setField(initialFieldState);
    }
    if(props.isForm) {
      setIsForm(true);
    };

    const user = localStorageService.getObject('user');
    setCogntioUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
  }, [props]); 

  const [typeOptions] = useState({
    text: ["string"],
    textarea: ["string"],
    selection: ["object", "array", "string"],
    dropdown: ["object", "array", "string"],
    checkbox: ["boolean"],
    number: ["integer", "float", "double"],
    upload: ["base64", "string", "file"],
    date: ["string", "date", "datetime"],
    table: ["object", "array", "string"],
    signature: ["object"],
    time: ["string", "date", "datetime"],
  });

  const [fieldTemplate] = useState({
    name: 'string',
    label: 'string',
    type: 'select',
    dataType: 'select',
    length: 'number',
    required: 'boolean',
    wrapping: 'boolean',
    visible: 'boolean',
    placeholdertext: 'string',
    options: 'text',
    dependant: 'text'
  });

  const [field, setField] = useState(props.field);
  const [changedValue, setChangedValue] = useState(false);
  const [optionsEnabled, setOptionsEnabled] = useState(false);
  const [dependantEnabled, setDependantEnabled] = useState(false);
  const [allFields, setAllFields] = useState([{name: ''}]);
  const [isForm, setIsForm] = useState(false);
  const [cogntioUser, setCogntioUser] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isArrowDown, setIsArrowDown] = useState(false);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [optionsModalOpen, setOptionsModalOpen] = useState(false);
  const [dependantModalOpen, setDependantModalOpen] = useState(false);
  const [tableEnabled, setTableEnabled] = useState(false);
  const [tableModalOpen, setTableModalOpen] = useState(false);

  const convertToCapital = (value) => {
    return `${value[0].toUpperCase()}${value.slice(1)}`;
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setField(prevState => ({
      ...prevState,
      [name]: type === 'number' ? parseInt(value) : value
    }));
    setChangedValue(true);

    if(name === 'type'){
      const selectedType = value;
      setField(prevState => ({
        ...prevState,
        dataType: typeOptions[selectedType][0] // Set default dataType based on selected type
      }));

      if(['dropdown', 'selection'].includes(value)) {
        setOptionsEnabled(true);
      } else {
        setOptionsEnabled(false);
      };

      if(['table'].includes(value)){
        setTableEnabled(true);
      } else {
        setTableEnabled(false);
      };
    }
  };

  const handleMouseOut = () => {
    if(changedValue){
      if(isForm){
        props.updateFieldsArray(field, props.indexFieldArray);
      }
      setChangedValue(false);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setField(prevState => ({
      ...prevState,
      [name]: checked
    }));
    setChangedValue(true);
  };  

  const handleRemoveField = () => {
    if(isForm){
      props.updateFieldsArray(undefined, props.indexFieldArray);
    };
  };

  const removeOptionalKeys = (field) => {
    delete field.options;
    if (field.placeholdertext === '') {
      delete field.placeholdertext; 
    }
    if ( field.api && Object.keys(field.api).length === 0) {
      delete field.api;
    } 
    if (!optionsEnabled && field?.api && Object.keys(field?.api).length !== 0){
      delete field.api;
    }
    if(field.dependant === ''){
      delete field.dependant;
    }
    debugger
    if (field.type!=='table') {
      if(field?.tableColumns) {
        if(field.tableColumns.length > 0){
          delete field.tableColumns;
        }
      }
    }
  };  

  const handleSubmitField = async () => {
    const displayName = field.name;
    const currentField = field;
    removeOptionalKeys(field);
    const fieldObject = {
        displayName: displayName,
        field: field
    };
    console.log("Field to submit: ", fieldObject);
    if (validationService.hasEmptyKeys(fieldObject)) {
      setField(currentField); 
      alert('Field keys can not be empty!');
    } else {
        try {
          console.log("Submitted Field: ", fieldObject);
          const response = await apiGatewayService.postFieldsData(fieldObject);
          if(response.data.errorName && response.data.errorName === 'KeyExistsAlready' ){
            alert("Field with same name already exists!!!");
          } else {
            props.handleClose();
          }

        } catch (error) {
          console.log("Error in submitting form options: ", error);
          setField(currentField); 
        }
    };
  };

  const getOptionName = (api) => {
    if(api.url) {
      return api.url.split('/')[1].split('.')[0];
    }
    return '';
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
    setOptionsModalOpen(false);
    setDependantModalOpen(false);
    setTableModalOpen(false);
  };

  const fieldKeys = Object.keys(fieldTemplate);

  const excludeFirstElement = (inputString) => {
    const parts = inputString.split('/');
    parts.shift();
    return parts.join('/');
  };
  
  const generateApiObjectForField = (option) => {
    const url = excludeFirstElement(option.path);
    return {
      url,
      label: "VALID_VALUE_DESCRIPTION",
      value: "VALID_VALUE_ID",
    };
  };

  const addOptionsIntoField = (api) => {
    console.log("api object received: ", api);
    field.api = api;
    field.options = getOptionName(api);
    closeModal();
  };

  const handleAddDependant = () => {
    // setDependantEnabled(true);
    setDependantModalOpen(true);
  };

  const handleAddOption = () => {
    setOptionsModalOpen(true);
  };

  const handleAddTable = () => {
    console.log("Add table clicked!");
    setTableModalOpen(true);
  };

  const submitDependant = (value, dependantTitle) => {
    console.log('Submitted dependant is: ', value);
    field['dependant'] = {
      title: dependantTitle,
      field: value
    };
    setDependantEnabled(false);
  };

  const removeDependant = () => {
    setDependantEnabled(false);
    delete field['dependant'];
  };

  const handleMouseEnterMain = () => {
    setIsEdit(true);
  };
  
  const handleMouseOutMain = () => {
    setIsEdit(false);
  };

  const toggleEditArrow = () => {
    setIsArrowDown(!isArrowDown);
  };

  const handleDropdownAndSelectedOnInput = (field) => {
    if(['dropdown','selection'].includes(field.type)){
      setOptionsEnabled(true);
    };
    if(['table'].includes(field.type)){
      setTableEnabled(true);
    }
  };

  const handleAddTableColumns = (tableColumns) => {
    console.log("tableColumns: ", tableColumns);
    if (tableColumns.length > 0) {
      field.tableColumns = tableColumns;
    } else {
      delete field['tableColumns']
    }
  };


  return (
    <div className={ utilityFunctions.isEven(props.indexFieldArray) ?  classes.FormFieldContainerTop2 : classes.FormFieldContainerTop1 }>
      { !isForm && <div className={classes.HeaderContainer}><h2 style={{ padding: '0px', margin: '0px' }}>Field Builder</h2></div>}
      <div style={{ position: 'relative' }}>
        <IconButton
          sx={{
            padding: 0,
            margin: 0,
            position: 'absolute',
            top: 0,
            right: 0,
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={toggleEditArrow}
        >
          <MoreHorizIcon sx={{ fontSize: 35 }} />
        </IconButton>
      </div>
      <div className={isArrowDown ? classes.FormFieldContainerVertical : classes.FormFieldContainer} onMouseEnter={handleMouseEnterMain} onMouseLeave={handleMouseOutMain}>
        {fieldKeys.map((key, index) => (
          <div key={index} className={ ((['name','label','type','dataType', 'required'].includes(key) && !isArrowDown) || isArrowDown) ? classes.Field : classes.ClassesNone}>
            <InputLabel sx={{ mt: 1, mb: 1 }}>{convertToCapital(key)}</InputLabel>
            <div>
              {fieldTemplate[key] === 'boolean' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={key}
                      checked={field[key]}
                      onChange={handleCheckboxChange}
                      onMouseOut={handleMouseOut}
                      sx={{ p: 0, m: 0, height: '10px', width: '10px', '& .MuiSvgIcon-root': { fontSize: '30px' } }}
                    />
                  }
                  sx={{ m: 0, ml: '5px', p: 0 }}
                />
              ) : (fieldTemplate[key] === 'number' && key === 'length') ? (
                <TextField
                  type="text"
                  name={key}
                  value={field[key]}
                  onChange={handleInputChange}
                  onBlur={handleMouseOut}
                  variant="outlined"
                  size="medium"
                  disabled={optionsEnabled}
                  sx={{
                    width: '100%',
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: 'white'
                    },
                    "& .MuiOutlinedInput-input": {
                      height: 40,
                      width: '100%',
                    },
                  }}
                />
              ) : fieldTemplate[key] === 'number' ? (
                <TextField
                  type="number"
                  name={key}
                  value={field[key]}
                  onChange={handleInputChange}
                  onBlur={handleMouseOut}
                  variant="outlined"
                  size="medium"
                  sx={{
                    width: '100%',
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: 'white'
                    },
                    "& .MuiOutlinedInput-input": {
                      height: 40,
                      width: '100%',
                    },
                  }}
                />
              ) : (fieldTemplate[key] === 'select' && key === 'type') ? (
                <FormControl
                  variant="outlined"
                  className={classes.Select}
                  sx={{ minWidth: '100%' }} 
                >
                  <Select
                    name={key}
                    value={field[key] || ''}
                    onChange={handleInputChange}
                    onBlur={handleMouseOut}
                    sx={{ height: '40px', backgroundColor: 'white' }}
                  >
                    {Object.keys(typeOptions).map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : 
              (fieldTemplate[key] === 'select' && key === 'dataType') ? (
                <FormControl
                  variant="outlined"
                  className={classes.Select}
                  sx={{ minWidth: '100%' }}
                >
                  <Select
                    name={key}
                    value={field[key] || ''}
                    onChange={handleInputChange}
                    onBlur={handleMouseOut}
                    disabled={optionsEnabled || tableEnabled}
                    sx={{ height: '40px', backgroundColor: 'white' }}
                  >
                    {typeOptions[field.type]?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (fieldTemplate[key] === 'text' && key === 'options') ? (
                <TextField
                  type="text"
                  name={key}
                  value={field[key]}
                  onChange={handleInputChange}
                  onBlur={handleMouseOut}
                  disabled={true}
                  variant="outlined"
                  size="medium"
                  sx={{
                    width: '100%',
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: 'white'
                    },
                    "& .MuiOutlinedInput-input": {
                      height: 40,
                      width: '100%',
                    },
                  }}
                />
              ) : 
              (fieldTemplate[key] === 'text' && key === 'dependant') ? (
                <TextField
                  type="text"
                  name={key}
                  value={field[key]?.field ? field[key]?.field : ''}
                  onChange={handleInputChange}
                  onBlur={handleMouseOut}
                  disabled={true}
                  variant="outlined"
                  size="medium"
                  sx={{
                    width: '100%',
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: 'white'
                    },
                    "& .MuiOutlinedInput-input": {
                      height: 40,
                      width: '100%',
                    },
                  }}
                />
              ) : (
                <TextField
                  type="text"
                  name={key}
                  value={field[key]}
                  onChange={handleInputChange}
                  onBlur={handleMouseOut}
                  variant="outlined"
                  size="medium"
                  sx={{
                    width: '100%',
                    "& .MuiInputBase-root": {
                      height: 40,
                      backgroundColor: 'white'
                    },
                    "& .MuiOutlinedInput-input": {
                      height: 40,
                      width: '100%',
                    },
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {!isOnlyReadAccess && (
        <div className={classes.ButtonDirection}>
          <Button
            onClick={handleRemoveField}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 0,
              background: 'transparent',
              color: 'var(--app-color)',
              cursor: 'pointer',
              borderRadius: '10px',
              ':hover': {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              height: '58px'
            }}
          >
            <RemoveCircleIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'red', padding: 0 }} />
            <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Remove</Typography>
          </Button>
          <Button
            onClick={handleSubmitField}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 0,
              background: 'transparent',
              color: 'var(--app-color)',
              cursor: 'pointer',
              borderRadius: '10px',
              ':hover': {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              height: '58px'
            }}
          >
            <BackupIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
            <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Save</Typography>
          </Button>
          <Button
            onClick={handleAddDependant}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 0,
              background: 'transparent',
              color: 'var(--app-color)',
              cursor: 'pointer',
              borderRadius: '10px',
              ':hover': {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              height: '58px'
            }}
          >
            <LowPriorityIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
            <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Dependant</Typography>
          </Button>
          {optionsEnabled &&
            <Button
              onClick={handleAddOption}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
                background: 'transparent',
                color: 'var(--app-color)',
                cursor: 'pointer',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                height: '58px'
              }}
            >
              <StyleIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
              <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Options</Typography>
            </Button>
          }
          {tableEnabled &&
            <Button
              onClick={handleAddTable}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
                background: 'transparent',
                color: 'var(--app-color)',
                cursor: 'pointer',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                height: '58px'
              }}
            >
              <FormatListNumberedIcon sx={{ fontSize: 30, marginTop: 1, marginBottom: '5px', color: 'var(--app-color)', padding: 0 }} />
              <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Table</Typography>
            </Button>
          }
          {/* {optionsEnabled && (
            <SelectionModalComponent 
              type={'Options'}
              Builder={FieldOptionsBuilder}
              Selector={FieldOptionsSelector}
              addOptionsIntoField={addOptionsIntoField}
              field={field}
            />
          )} */}
          {/* {dependantEnabled && (
            <FieldsDependantSelector
              submitDependant={submitDependant}
              fields={allFields}
              removeDependant={removeDependant}
              dependant={field?.dependant ? field?.dependant : null}
            />
          )} */}
        <FieldOptionsSelector 
        isOpen={optionsModalOpen} 
        handleClose={closeModal} 
        submitOption={addOptionsIntoField} 
        field={field}
        />
        <FieldsDependantSelector
        isOpen={dependantModalOpen}
        handleClose={closeModal}
        submitDependant={submitDependant}
        removeDependant={removeDependant}
        dependant = {field.dependant || undefined}
        />
      <TableColumns
        open={tableModalOpen} // Use "open" instead of "isOpen"
        onClose={closeModal} // Use "onClose" instead of "handleClose"
        onSubmit={handleAddTableColumns} // "onSubmit" is correct
        tableColumnsInput = {field.tableColumns || []}
      />

        </div>
      )}
    </div>
  );
};

export default FieldBuilder;
