import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, List, ListItem, ListItemText, Typography, Container, Toolbar, AppBar, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewListIcon from '@mui/icons-material/ViewList';
import SectionsModal from '../SectionsModal/SectionsModal';
import apiGatewayService from '../../common/services/apiGatewayService';
import validationService from '../../common/validationService.service';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import CircularLoader from '../Loader/Loader';
import SectionBuilder from '../FormBuilder/SectionBuilder/SectionBuilder';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const Fields = () => {
  const [sections, setSections] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewIndex, setIsViewIndex] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnlyReadAcess, setIsOnlyReadAccess] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);


  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const handleClose = async () => {
    setOpen(false);
    setAllFalse();
    await getAllSections();
  }

  

  useEffect(() => {
    const getOptions = async (user) => {
      try {
        const response = await apiGatewayService.getSectionsData();
        console.log("get sections response: ", response);
        // const dataFiltered = response.data.filter((d)=>(d.Content!==""));
        // const dataWithUpdatedKeyAndContent = utilityFunctions.getKeyAndContent(dataFiltered);
        const dataWithUpdatedKeyAndContent = response.data;
        dataWithUpdatedKeyAndContent.sort((a, b) => {
          if (a.sectionName < b.sectionName) {
            return -1;
          }
          if (a.sectionName > b.sectionName) {
            return 1;
          }
          return 0;
        });
        console.log("response data updated: ", dataWithUpdatedKeyAndContent); 
        setSections(dataWithUpdatedKeyAndContent);
      } catch (error) {
        console.error('Error fetching fields:', error);
      }
      setIsLoading(false);
    };
    const user = localStorageService.getObject('user');
    console.log("currentCognito User: ", user);
    setCognitoUser(user);
    setCognitoUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    getOptions(user);
  }, []);

  const getAllSections = async (user) => {
    try {
      const response = await apiGatewayService.getSectionsData();
      console.log("get sections response: ", response);
      // const dataFiltered = response.data.filter((d)=>(d.Content!==""));
      // const dataWithUpdatedKeyAndContent = utilityFunctions.getKeyAndContent(dataFiltered);
      const dataWithUpdatedKeyAndContent = response.data;
      dataWithUpdatedKeyAndContent.sort((a, b) => {
        if (a.sectionName < b.sectionName) {
          return -1;
        }
        if (a.sectionName > b.sectionName) {
          return 1;
        }
        return 0;
      });
      console.log("response data updated: ", dataWithUpdatedKeyAndContent); 
      setSections(dataWithUpdatedKeyAndContent);
    } catch (error) {
      console.error('Error fetching fields:', error);
    }
  };

  const setAllFalse = () => {
    setIsAddNew(false);
    setIsImport(false);
    setIsView(false);
    setIsEdit(false);
    setIsViewIndex(null);
  };

  const handleAddNew = () => {
    setAllFalse();
    setIsAddNew(true);
    handleOpen();
  };

  const handleImport = () => {
    setAllFalse();
    setIsImport(true);
    alert("This feature is in progress.");
  };

  const handleView = (index) => {
    setAllFalse();
    setIsView(true);
    setIsViewIndex(index);
  };

  const handleEdit = async (index) => {
    setAllFalse();
    console.log("Inside Edit Index Is: ", index);
    setIsViewIndex(index);
    await getSectionById(sections[index].sectionName);
    setTimeout(()=>{
      setIsEdit(true);
      handleOpen();
    },300)

    // setIsEdit(true);
    // handleOpen();
  };

  const handleDelete = async (index) => {
    try {
      const postData = {
        sectionNames: [sections[index].sectionName],
      }
      await apiGatewayService.deleteSectionsData(postData);
      setAllFalse();
      const sectionsNew = sections.filter((option, index2)=>index2!==index);
      setSections(Array.from(sectionsNew));
    } catch(error){
      console.log("Error: ", error);
      alert('Section cannot be deleted');
    };
  };

  const getSectionById = async (sectionId) => {
    try {
      setIsLoading(true);
      const response =  await apiGatewayService.getSectionsDataById(sectionId);
      console.log('Get section by Id response: ', response);
      setCurrentSection(response.data);
    } catch(error) {
      console.log('Error in getting sections: ', error);
    }
    setIsLoading(false);
  };


  return (
    <Container sx={{ padding: '0px'}}>
      {/* <AppBar position="static" sx={{ backgroundColor: 'white', borderRadius: "0px", padding: '0px'}}> */}
        <Toolbar sx={{ backgroundColor: 'var(--app-color-secondary)', borderRadius: "10px", padding: '0px', color: 'white'}}>
        <IconButton  sx = {{marginLeft: '-20px'}} edge="start" color="inherit" aria-label="menu">
            < ViewListIcon />
          </IconButton>
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            Sections
          </Typography>
          {(isEdit || isAddNew) && 

          <Button sx={{
            width: '100px',
            marginRight: '-10px',
            ":hover": {
              backgroundColor: '#F0F4F4',
              color: '#2b4252',
            },
            '&.Mui-disabled': {
              backgroundColor: '#e0e0e0', // Custom background color for disabled state
              color: 'grey', // Custom text color for disabled state
            },
          }} color="inherit" startIcon={<KeyboardBackspaceIcon  sx ={{fontSize: '100px'}}/>} onClick={handleClose} disabled={isOnlyReadAcess}>
            Back
          </Button>}
        { (!isEdit && !isAddNew ) &&
        <div>
        <Button sx={{
          marginRight: '10px',
          ":hover": {
            backgroundColor: '#F0F4F4',
            color: '#2b4252',
          },
          '&.Mui-disabled': {
            backgroundColor: '#e0e0e0', // Custom background color for disabled state
            color: 'grey', // Custom text color for disabled state
          },
        }} color="inherit" startIcon={<AddIcon />} onClick={handleAddNew} disabled={isOnlyReadAcess}>
          Add New
        </Button>
        <Button sx={{
          marginRight: '-10px',
          ":hover": {
            backgroundColor: '#F0F4F4',
            color: '#2b4252',
          },
          '&.Mui-disabled': {
            backgroundColor: '#e0e0e0', // Custom background color for disabled state
            color: 'grey', // Custom text color for disabled state
          },
        }}
          color="inherit" startIcon={<ImportExportIcon />} onClick={handleImport} disabled={isOnlyReadAcess}>
          Import
        </Button>
        </div>
        }
        </Toolbar>
      {/* </AppBar> */}
      
      {isLoading && <CircularLoader></CircularLoader>}
      { (!isLoading && !isEdit && !isAddNew)  &&
      <List  sx = {{flexGrow: 1, p: 0}}>
        {sections.map((option, index) => (
          <ListItem key={index} sx={{ display: 'flex', alignItems: 'center',flexGrow:1, pt:'6px',pb:'6px',pl:0, pr: 0 }}>
            <Paper sx={{ flexGrow: 1, p: 1 }}>
              <ListItemText primary={option.sectionName} />
            </Paper>
            <IconButton aria-label="view" onClick={() => handleView(index)} disabled = {isOnlyReadAcess}>
              <VisibilityIcon />
            </IconButton>
            <IconButton sx =  {{color: '#2b4252'}} aria-label="edit" onClick={() => handleEdit(index)}>
              <EditIcon />
            </IconButton>
            <IconButton sx =  {{color: '#CC3824'}} aria-label="delete" onClick={() => handleDelete(index)} disabled = {isOnlyReadAcess}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>}
      {/* { isAddNew &&   <SectionsModal 
        open={open} 
        handleClose={handleClose}
        section = {{}}
      />}
      {
        isEdit && <SectionsModal 
        open={open} 
        handleClose={handleClose}
        section = {sections[viewIndex]}
      />
      } */}

      {
        isAddNew && <SectionBuilder section = {{}} handleClose={handleClose}/>
      }

      {
        isEdit && <SectionBuilder  section = {currentSection} handleClose={handleClose}/>
      }
    </Container>
  );
};

export default Fields;
