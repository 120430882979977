import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Select,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';

const TableColumns = ({ open, onClose, onSubmit, tableColumnsInput }) => {
  const [tableColumns, setTableColumns] = useState([]);

  useEffect(() => {
    if (tableColumnsInput.length > 0) {
      setTableColumns(Array.from(tableColumnsInput));
    }
  }, [open, tableColumnsInput]);

  const handleAdd = () => {
    setTableColumns([...tableColumns, { name: '', type: 'text' }]);
  };

  const handleUpdateName = (index, value) => {
    const updatedColumns = [...tableColumns];
    updatedColumns[index].name = value;
    setTableColumns(updatedColumns);
  };

  const handleUpdateType = (index, value) => {
    const updatedColumns = [...tableColumns];
    updatedColumns[index].type = value;
    setTableColumns(updatedColumns);
  };

  const handleRemove = (index) => {
    const updatedColumns = tableColumns.filter((_, i) => i !== index);
    setTableColumns(updatedColumns);
  };

  const moveUp = (index) => {
    if (index === 0) return;
    const updatedColumns = [...tableColumns];
    [updatedColumns[index - 1], updatedColumns[index]] = [updatedColumns[index], updatedColumns[index - 1]];
    setTableColumns(updatedColumns);
  };

  const moveDown = (index) => {
    if (index === tableColumns.length - 1) return;
    const updatedColumns = [...tableColumns];
    [updatedColumns[index + 1], updatedColumns[index]] = [updatedColumns[index], updatedColumns[index + 1]];
    setTableColumns(updatedColumns);
  };

  const handleSubmit = () => {
    onSubmit(tableColumns);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" gutterBottom>
          Table Columns
        </Typography>
        <List>
          {tableColumns.map((column, index) => (
            <ListItem key={index} sx={{ p: 0, mt: 1 }}>
              <TextField
                value={column.name}
                onChange={(e) => handleUpdateName(index, e.target.value)}
                fullWidth
                placeholder={`Column ${index + 1} Name`}
                sx={{
                  width: '50%',
                  mr: 1,
                  "& .MuiInputBase-root": {
                    height: 40,
                    backgroundColor: 'white',
                  },
                  "& .MuiOutlinedInput-input": {
                    height: 40,
                    width: '100%',
                  },
                }}
              />
              <Select
                value={column.type}
                onChange={(e) => handleUpdateType(index, e.target.value)}
                sx={{
                  width: '30%',
                  mr: 1,
                  height: 40,
                  backgroundColor: 'white',
                }}
              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="date">Date</MenuItem>
                <MenuItem value="time">Date</MenuItem>
              </Select>
              <Box sx ={{diplay: 'flex', ml: '85px'}}>
              <ListItemSecondaryAction sx={{ ml: '-10px' }}>
                <IconButton edge="end" onClick={() => moveUp(index)} disabled={index === 0}>
                  <ArrowUpwardIcon sx={{ color: 'var(--button-color)', ml: 3, ':hover': { color: 'var(--button-hover-color)', backgroundColor: 'transparent' } }} />
                </IconButton>
                <IconButton edge="end" onClick={() => moveDown(index)} disabled={index === tableColumns.length - 1}>
                  <ArrowDownwardIcon sx={{ color: 'var(--button-color)', ':hover': { color: 'var(--button-hover-color)', backgroundColor: 'transparent' } }} />
                </IconButton>
                <IconButton edge="end" onClick={() => handleRemove(index)}>
                  <DeleteIcon sx={{ color: 'var(--button-color-red)', ":hover": { color: 'var(--button-hover-color-red)' } }} />
                </IconButton>
              </ListItemSecondaryAction>
              </Box>
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          onClick={handleAdd}
          sx={{
            mt: 2,
            width: '100%',
            backgroundColor: 'var(--button-color)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
        >
          Add Column
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            mt: 2,
            width: '100%',
            backgroundColor: 'var(--button-color)',
            color: 'white',
            fontWeight: 'normal',
            textTransform: 'none',
            ":hover": {
              backgroundColor: 'var(--button-hover-color)',
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default TableColumns;