import React, { useState, useEffect } from 'react';
import apiGatewayService from '../../../common/services/apiGatewayService';
import localStorageService from '../../../common/services/localStorage.service';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FieldsDependantSelector = ({ isOpen, handleClose, submitDependant, removeDependant, dependant }) => {
  const [availableFields, setAvailableFields] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [dependentTitle, setDependentTitle] = useState('');

  useEffect(() => {
    console.log('dependant input: ', dependant);
    if (dependant && dependant.title) {
      setDependentTitle(dependant.title);
      setSelectedField(dependant.field);
    }
  }, [dependant]);

  useEffect(() => {
    const getAllFields = async (user) => {
      try {
        const response = await apiGatewayService.getFieldsData();
        const dataWithUpdatedKeyAndContent = response.data;
        setAvailableFields(dataWithUpdatedKeyAndContent);
      } catch (error) {
        console.error('Error fetching fields:', error);
      }
    };
    const user = localStorageService.getObject('user');
    getAllFields(user);
  }, []);

  const handleChange = (event) => {
    setSelectedField(event.target.value);
  };

  const handleSubmit = () => {
    debugger
    const selectedOption = availableFields.find((option) => option.fieldName === selectedField);
    submitDependant(selectedOption.fieldName, dependentTitle);
    handleClose();
  };

  const handleTitleInputChange = (e) => {
    setDependentTitle(e.target.value);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="fields-dependant-selector-title"
      aria-describedby="fields-dependant-selector-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: 24,
          width: '400px', // Adjust the width as needed
          outline: 'none',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Typography id="fields-dependant-selector-title" variant="h6" component="h2" gutterBottom>
          Select Dependant
        </Typography>

        <InputLabel sx={{ mt: 2 }}>Title</InputLabel>
        <TextField
          fullWidth
          variant="outlined"
          name="Title"
          onChange={handleTitleInputChange}
          value={dependentTitle}
          sx={{
            marginBottom: '16px',
            "& .MuiInputBase-root": {
              height: 40,
              backgroundColor: 'white',
            },
            "& .MuiOutlinedInput-input": {
              height: 40,
              width: '100%',
            },
          }}
        />

        <InputLabel sx={{ mt: 2 }}>Dependents</InputLabel>
        <Select
          fullWidth
          value={selectedField}
          onChange={handleChange}
          displayEmpty
          sx={{ height: '40px', backgroundColor: 'white', marginBottom: '16px' }}
        >
          <MenuItem value="" disabled>
            <em>Select a dependent field</em>
          </MenuItem>
          {availableFields.map((option, index) => (
            <MenuItem key={index} value={option.fieldName}>
              {option.fieldName}
            </MenuItem>
          ))}
        </Select>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              backgroundColor: 'var(--button-color)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={removeDependant}
            sx={{
              backgroundColor: 'var(--button-color-red)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color-red)',
              },
            }}
          >
            Remove
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FieldsDependantSelector;