import React, {useEffect, useState} from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import apiGatewayService from '../../common/services/apiGatewayService';

const FormSubmitOptions = () => {
  const navigate = useNavigate();
  const [formId, setFormId] = useState();
  const [submittedFormId, setSubmittedFormId] = useState();
  const [companyAlias, setCompanyAlias] = useState();

  useEffect(()=>{
    const getCompanyAlias = async () => {
        try {
          const response = await apiGatewayService.getCompanyAlias();
          setCompanyAlias(response.data[0].companyAlias);
        } catch (error) {
          console.error('Error getting company alias:', error);
        }
    };
    const temp = window.location.pathname.split('/');
    const submittedFormId = temp[temp.length - 1];
    const formId = temp[temp.length - 2];
    console.log("submittedFormId: ", submittedFormId);
    console.log("formId: ", formId);
    setSubmittedFormId(submittedFormId);
    setFormId(formId);
    getCompanyAlias();
  }, []);


  const handlePrintPdf = () => {
    // const newTab = window.open(`/printPreview/${formId}/${submittedFormId}`, '_blank', 'noopener,noreferrer');
    // if (newTab) newTab.opener = null;
    navigate(`/printPreview/${formId}/${submittedFormId}`);
  };

  const handleSubmitNew = () => {
    // const newTab = window.open(`/displayForm/${companyAlias}/${formId}`, '_blank', 'noopener,noreferrer');
    // if (newTab) newTab.opener = null;
    navigate(`/displayForm/${companyAlias}/${formId}`);
  };

  return (
        <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        gap={2} 
        mt={4}
        >
        <Typography 
            variant="h1" 
            align="center"
            sx={{
            fontSize: '2rem', // Adjust font size for h1 equivalent
            fontWeight: 'bold', // You can customize the style further as needed
            }}
        >
            Thank you For Submitting The Form
        </Typography>

        <Box 
            display="flex" 
            flexDirection="row" 
            justifyContent="center"
            gap={2} // Add gap between buttons
            mt={2}
        >
            <Button 
            variant="contained" 
            color="primary" 
            onClick={handlePrintPdf} 
            sx={{
                backgroundColor: 'var(--button-color)',
                color: 'white',
                fontWeight: 'normal',
                textTransform: 'none',
                ":hover": {
                backgroundColor: 'var(--button-hover-color)',
                },
            }}
            >
            Print PDF
            </Button>
            
            <Button 
            variant="contained" 
            color="secondary" 
            onClick={handleSubmitNew} 
            sx={{
                backgroundColor: 'var(--button-color)',
                color: 'white',
                fontWeight: 'normal',
                textTransform: 'none',
                ":hover": {
                backgroundColor: 'var(--button-hover-color)',
                },
            }}
            >
            Submit New
            </Button>
        </Box>
        </Box>

    );
};

export default FormSubmitOptions;
