import React, { useState, useEffect } from 'react';
import classes from './FieldOptionsSelector.module.css';
import apiGatewayService from '../../../common/services/apiGatewayService';
import localStorageService from '../../../common/services/localStorage.service';
import utilityFunctions from '../../../common/services/utilityFunctions';
import validationService from '../../../common/validationService.service';
import { CircularProgress, TextField, MenuItem, Select, InputLabel, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FieldOptionsSelector = ({ isOpen, handleClose, submitOption, field }) => {
  const [availableOptions, setAvailableOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    title: '',
    values: [],
  });
  const [optionSelected, setOptionSelected] = useState(false);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [label, setLabel] = useState('');
  const [value, setValue] = useState('');
  const [displayPattern, setDisplayPattern] = useState('');
  const [defaultValues, setDefaultValues] = useState([]);
  const [defaultValue, setDefaultValue] = useState('');
  const [valueHelpFilters, setValueHelpFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptionId, setSelectedOptionId] = useState(null);

  useEffect(() => {
    const getOptions = async (user) => {
      try {
        setIsLoading(true);
        const response = await apiGatewayService.getOptionsData();
        const dataWithUpdatedKeyAndContent = response.data;
        dataWithUpdatedKeyAndContent.push({
          title: '',
          values: [],
        });
        setAvailableOptions(dataWithUpdatedKeyAndContent);

        if (field.api) {
          setInitialValues(field.api, dataWithUpdatedKeyAndContent);
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
      setIsLoading(false);
    };

    const user = localStorageService.getObject('user');
    getOptions(user);
  }, [field.api]);

  const setInitialValues = async (api, options) => {
    debugger
    setLabel(api.label);
    setValue(api.value);
    setDisplayPattern(api.displayPattern);
    setDefaultValue(api.default || '');
    if (api.valueHelpFilters) {
      setValueHelpFilters(api.valueHelpFilters);
    }

    const currOption = options.find((o) => api.url.includes(o.optionId)) || [];
    if(currOption) {
      const optionValues = await getOptionDataById(currOption.optionId);
      setCurrentOption(currOption, optionValues);
    }
    // if (currOption.values.length > 0) {
    //   setCurrentOption(currOption);
    // }
  };

  const setCurrentOption = (currOption, optionValues) => {
    setSelectedOption(currOption);
    setOthers(optionValues);
    setOptionSelected(true);
  };

  const removeFiltersAndAttributes = (keys) => {
    return keys.filter((k) => !k.includes('FILTER') && !k.includes('ATTRIBUTE'));
  };

  const setOthers = (optionValues) => {
    console.log("option value: ", optionValues);
    setValues(removeFiltersAndAttributes(Object.keys(optionValues[0])));
    setLabels(removeFiltersAndAttributes(Object.keys(optionValues[0])));
    setDefaultValues(optionValues.map((o) => o['VALID_VALUE_DESCRIPTION']));
  };

  const resetOthers = () => {
    setValues([]);
    setLabels([]);
    setDefaultValues([]);
    setValue('');
    setLabel('');
    setDefaultValue('');
  };

  const handleChange = async (event) => {
    if (event.target.value !== '') {
      resetOthers();
      const selectedOption = await getOptionDataById(event.target.value);
      setSelectedOptionId(event.target.value);
      setSelectedOption({optionId: event.target.value});
      setOthers(selectedOption);
      setOptionSelected(true);
    } else {
      setSelectedOption('');
      resetOthers();
      setOptionSelected(false);
    }
  };

  const getOptionDataById = async (optionId) => {
    try {
      const response = await apiGatewayService.getOptionsDataById(decodeURIComponent(optionId));
      return response.data;
    } catch (error) {
      console.log('Error in getting option by Id: ', error);
    }
  };

  const handleLabelChange = (event) => {
    setLabel(event.target.value);
  };

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleDefaultValueChange = (event) => {
    setDefaultValue(event.target.value);
  };

  const handleDisplayPatternChange = (event) => {
    setDisplayPattern(event.target.value);
  };

  const generateAPIObject = () => {
    const api = {
      url: selectedOption.optionId ? `options/${ selectedOption.optionId}` : '',
      label: label,
      value: value,
      displayPattern: displayPattern,
      default: defaultValue,
      valueHelpFilters: valueHelpFilters,
    };
    return api.url === '' ? {} : api;
  };

  const removeOptionalKeys = (api) => {
    if (api?.valueHelpFilters?.length === 0) {
      delete api.valueHelpFilters;
    }

    if (api.default === '') {
      delete api.default;
    }

    return api;
  };

  const handleSubmit = () => {
    let api = generateAPIObject();
    api = removeOptionalKeys(api);
    if (validationService.hasEmptyKeys(api)) {
      alert('Please select required values for options');
    } else {
      submitOption(api);
      handleClose();
    }
  };

  const handleRemove = () => {
    submitOption({});
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>Select Options</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress sx={{ color: 'var(--app-color)' }} />
          </div>
        ) : (
          <>
            <InputLabel sx={{ mt: '10px' }}>Option</InputLabel>
            <Select
              fullWidth
              value={selectedOption.optionId}
              onChange={handleChange}
              displayEmpty
              sx={{ height: '40px', backgroundColor: 'white' }}
            >
              <MenuItem value="" disabled>
                <em>Select an option</em>
              </MenuItem>
              {availableOptions.map((option, index) => (
                <MenuItem key={index} value={option.optionId}>
                  {option.optionId}
                </MenuItem>
              ))}
            </Select>

            <InputLabel sx={{ mt: '10px' }}>Label</InputLabel>
            <Select
              fullWidth
              value={label}
              onChange={handleLabelChange}
              displayEmpty
              disabled={!optionSelected}
              sx={{ height: '40px', backgroundColor: 'white' }}
            >
              <MenuItem value="" disabled>
                <em>Select a label</em>
              </MenuItem>
              {labels.map((labelOption, index) => (
                <MenuItem key={index} value={labelOption}>
                  {labelOption}
                </MenuItem>
              ))}
            </Select>

            <InputLabel sx={{ mt: '10px' }}>Value</InputLabel>
            <Select
              fullWidth
              value={value}
              onChange={handleValueChange}
              displayEmpty
              disabled={!optionSelected}
              sx={{ height: '40px', backgroundColor: 'white' }}
            >
              <MenuItem value="" disabled>
                <em>Select a value</em>
              </MenuItem>
              {values.map((valueOption, index) => (
                <MenuItem key={index} value={valueOption}>
                  {valueOption}
                </MenuItem>
              ))}
            </Select>

            <InputLabel sx={{ mt: '10px' }}>Default Value</InputLabel>
            <Select
              fullWidth
              value={defaultValue}
              onChange={handleDefaultValueChange}
              displayEmpty
              disabled={!optionSelected}
              sx={{ height: '40px', backgroundColor: 'white' }}
            >
              <MenuItem value="" disabled>
                <em>Select a default value</em>
              </MenuItem>
              {defaultValues.map((defaultOption, index) => (
                <MenuItem key={index} value={defaultOption}>
                  {defaultOption}
                </MenuItem>
              ))}
            </Select>

            <InputLabel sx={{ mt: '10px' }}>Display Pattern</InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              name="Display Pattern"
              placeholder="Display Pattern"
              value={displayPattern}
              onChange={handleDisplayPatternChange}
              sx={{
                marginBottom: '16px',
                width: '100%',
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: 'white',
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: '100%',
                },
              }}
            />
          </>
        )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              backgroundColor: 'var(--button-color)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color)',
              },
            }}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRemove}
            sx={{
              backgroundColor: 'var(--button-color-red)',
              color: 'white',
              fontWeight: 'normal',
              textTransform: 'none',
              ":hover": {
                backgroundColor: 'var(--button-hover-color-red)',
              },
            }}
          >
            Remove
          </Button>
        </Box>
      </DialogContent>

    </Dialog>
  );
};

export default FieldOptionsSelector;