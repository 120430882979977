import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, List, ListItem, ListItemText, Typography, Container, Toolbar, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import apiGatewayService from '../../common/services/apiGatewayService';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import CircularLoader from '../Loader/Loader';
import FieldBuilder from '../FormBuilder/FieldBuilder/FieldBuilder';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const Fields = () => {
  const [fields, setFields] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewIndex, setIsViewIndex] = useState(null);
  const [currentField, setCurrentField] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = async () => {
    setOpen(false);
    setAllFalse();
    await getAllFields();
  }

  useEffect(() => {
    const getOptions = async (user) => {
      try {
        const response = await apiGatewayService.getFieldsData();
        // const dataFiltered = response.data.filter((d) => d.Content !== "");
        // const dataWithUpdatedKeyAndContent = utilityFunctions.getKeyAndContentFields(dataFiltered);
        const dataWithUpdatedKeyAndContent = response.data;
        dataWithUpdatedKeyAndContent.sort((a, b) => {
          if (a.fie < b.fieldName) return -1;
          if (a.fieldName > b.fieldName) return 1;
          return 0;
        });
        setFields(Array.from(dataWithUpdatedKeyAndContent));
      } catch (error) {
        console.error('Error fetching fields:', error);
      }
      setIsLoading(false);
    };

    const user = localStorageService.getObject('user');
    setCognitoUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    getOptions(user);
  }, []);

  const getAllFields = async () => {
    try {
      const response = await apiGatewayService.getFieldsData();
      // const dataFiltered = response.data.filter((d) => d.Content !== "");
      // const dataWithUpdatedKeyAndContent = utilityFunctions.getKeyAndContentFields(dataFiltered);
      const dataWithUpdatedKeyAndContent = response.data;
      dataWithUpdatedKeyAndContent.sort((a, b) => {
        if (a.fie < b.fieldName) return -1;
        if (a.fieldName > b.fieldName) return 1;
        return 0;
      });
      setFields(Array.from(dataWithUpdatedKeyAndContent));
    } catch (error) {
      console.error('Error fetching fields:', error);
    }
  };

  const setAllFalse = () => {
    setIsAddNew(false);
    setIsEdit(false);
    setIsViewIndex(null);
  };

  const handleAddNew = () => {
    setAllFalse();
    setIsAddNew(true);
    handleOpen();
  };

  const handleImport = () => {
    setAllFalse();
    // setIsImport(true);
    alert("This feature is in progress.");
  };

  const handleEdit = async (index) => {
    setAllFalse();
    setIsViewIndex(index);
    await getFieldById(fields[index].fieldName);
    setTimeout(() => {
      setIsEdit(true);
      handleOpen();
    }, 300);
  };

  const handleDelete = async (index) => {
    try {
      const postData = {
        fieldNames: [fields[index].fieldName],
      };
      await apiGatewayService.deleteFieldsData(postData);
      setAllFalse();
      const fieldsNew = fields.filter((option, index2) => index2 !== index);
      setFields(Array.from(fieldsNew));
    } catch (error) {
      console.error("Error: ", error);
      alert('Field cannot be deleted');
    }
  };

  const getFieldById = async (fieldId) => {
    try {
      setIsLoading(true);
      const response = await apiGatewayService.getFieldsDataById(fieldId);
      setCurrentField(response.data);
    } catch (error) {
      console.error('Error in getting field:', error);
    }
    setIsLoading(false);
  };

  return (
    <Container sx={{ padding: '0px' }}>
      <Toolbar sx={{ backgroundColor: 'var(--app-color-secondary)', borderRadius: "10px", padding: '0px', color: 'white' }}>
        <IconButton sx={{ marginLeft: '-20px' }} edge="start" color="inherit" aria-label="menu">
          <FormatListBulletedIcon />
        </IconButton>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Fields
        </Typography>
        {
          (isEdit || isAddNew) &&
          <Button sx={{
            width: '100px',
            marginRight: '-10px',
            ":hover": {
              backgroundColor: '#F0F4F4',
              color: '#2b4252',
            },
            '&.Mui-disabled': {
              backgroundColor: '#e0e0e0',
              color: 'grey',
            },
          }} color="inherit" startIcon={<KeyboardBackspaceIcon sx={{ fontSize: '100px' }} />} onClick={handleClose} disabled={isOnlyReadAccess}>
            Back
          </Button>
        }
        {
          (!isEdit && !isAddNew) &&
          <div>
            <Button sx={{
              marginRight: '10px',
              ":hover": {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: 'grey',
              },
            }} color="inherit" startIcon={<AddIcon />} onClick={handleAddNew} disabled={isOnlyReadAccess}>
              Add New
            </Button>
            <Button sx={{
              marginRight: '-10px',
              ":hover": {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: 'grey',
              },
            }} color="inherit" startIcon={<ImportExportIcon />} onClick={handleImport} disabled={isOnlyReadAccess}>
              Import
            </Button>
          </div>
        }
      </Toolbar>

      {isLoading && <CircularLoader />}
      {(!isLoading && !isAddNew && !isEdit) &&
        <List sx={{ flexGrow: 1, p: 0 }}>
          {fields.map((option, index) => (
            <ListItem key={index} sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, pt: '6px', pb: '6px', pl: 0, pr: 0 }}>
              <Paper sx={{ flexGrow: 1, p: 1 }}>
                <ListItemText primary={option.fieldName} />
              </Paper>
              <IconButton aria-label="view" disabled={isOnlyReadAccess}>
                <VisibilityIcon />
              </IconButton>
              <IconButton sx={{ color: '#2b4252' }} aria-label="edit" onClick={() => handleEdit(index)} >
                <EditIcon />
              </IconButton>
              <IconButton sx={{ color: '#CC3824' }} aria-label="delete" onClick={() => handleDelete(index)} disabled={isOnlyReadAccess}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      }

      {isAddNew && <FieldBuilder field={{}} handleClose={handleClose} />}
      {isEdit && <FieldBuilder field={currentField} handleClose={handleClose} />}
    </Container>
  );
};

export default Fields;
