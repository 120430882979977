import React, { useEffect, useState } from 'react';
import SectionBuilder from '../SectionBuilder/SectionBuilder';
import classes from './FormSectionsBuilder.module.css';
import apiGatewayService from '../../../common/services/apiGatewayService';
import Modal from '../Modal/Modal';
import SelectionModalComponent from '../SelectionModal/SelectionModal';
import SectionsSelector from '../SectionsSelector/SectionsSelector';
import validationService from '../../../common/validationService.service';
import localStorageService from '../../../common/services/localStorage.service';
import utilityFunctions from '../../../common/services/utilityFunctions';
import { AWS_API } from '../../../globalVariables';
import FormAdvanceFeatures from '../../FormAdvanceFeatures/FormAdvanceFeatures';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, FormGroup, Checkbox , InputLabel} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Button, Tooltip } from "@mui/material";
import {TextField} from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import BackupIcon from '@mui/icons-material/Backup';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddIcon from '@mui/icons-material/Add';

const initialFeatures = [{name: 'Send Forms Via Email', id: 'email', checked: false, placeHolderText: 'enter email'}, {name: 'Generate PDF View', id:'pdf', checked: false, placeHolderText: 'enter text'}, {name: 'Custom Submission URL', id:'submit', checked: false, placeHolderText: 'enter submission url'}  ]

const FormSectionsBuilder = ({auth, form, handleClose, isNew}) => {

  useEffect(()=>{
    console.log("GOT THE AUTH HERE!!!!!!. Now let us check the user group name");
    // console.log(auth.user.signInUserSession.accessToken.payload["cognito:groups"]);
    // setUserGroupName(auth.user.signInUserSession.accessToken.payload["cognito:groups"][0]);
    if(form?.formHeaders){
      setFormHeaders(form.formHeaders);
      setSectionsArray(form.sections);
      setSubmitAPI(form.submitApi);
      if(form?.isEmail){
        selectedOptions['email'] = true;
        setIsEmail(form.isEmail);
      }; 
      if(form?.emailToSend){
        setEmailToSend(form.emailToSend);
      }
    };

    const user = localStorageService.getObject('user');
    setCogntioUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    console.log("currentCognito User: ", user);
  },[auth])

const initialSectionState = {
    title: "",
    fields: ""
};

const formHeaderTemplate = {
    ID: 'string',
    FormName: 'string',
    FormTypeId: 'string',
    FormDescription: 'string'
};

const initialFormHeader = {
  ID: '',
  FormName: '',
  // FormTypeId: '',
  // FormDescription: ''
};

  const [sectionsArray, setSectionsArray] = useState([]);

  const [sectionArrayCounter, setSectionArrayCounter] = ([]);

  const [formHeaders, setFormHeaders] = useState(initialFormHeader);

  const [email, setEmail] = useState();

  const [userGroupName, setUserGroupName] = useState('');

  const [isForm, setIsForm] = useState(false);
  
  const [cogntioUser, setCogntioUser] = useState(null);

  const [submitAPI, setSubmitAPI] = useState('');

  const [isOnlyReadAcess, setIsOnlyReadAccess] = useState(false);

  const [isSubmissionURL, setIsSubmissionURL] = useState(false);

  const [isEmail, setIsEmail] = useState(false);

  const [isAdvanceFeatures, setIsAdvanceFeatures] = useState(false);

  const [advanceFeatures, setAdvanceFeatures] = useState(initialFeatures);

  const [selectedOption, setSelectedOption] = useState('');

  const [selectedOptions, setSelectedOptions] = useState({});

  const [emailToSend, setEmailToSend] = useState('');

  const [sectionSelectorOpen, setSectionSelectorOpen] = useState(false);


  const handleAdvanceFeatureSelection = (event) => {
    const { name, checked } = event.target;
    console.log("name: ", name);
    console.log("checked: ", checked);
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [name]: checked,
    }));
    setFormAdvanceFeaturesSelection(name, checked);
  };

  const setFormAdvanceFeaturesSelection = (id, checked) => {
    if (id === 'email'){
      if(checked){
        setIsEmail(true)
      } else{
        setIsEmail(false)
      }
    }
  };

  const handleAddSection = () => {
    setSectionsArray([...sectionsArray, initialSectionState]);
  };

  const updateSectionsArray = (section, index) => {
    console.log("Inside Update Section Array!!!!!!!!!!!!!!!!!!!!!!!!!: ");
    console.log("FormSectionsBuilder section and index: ", section);
    console.log("FormSectionsBuilder section and index: ", index);
    if (section === undefined) {
      let newSectionsArray = Array.from(sectionsArray);
      newSectionsArray = newSectionsArray.filter((_, ind)=> ind!==index);
      setSectionsArray(newSectionsArray);
    }
    else {
      sectionsArray[index] = section;
      // newSectionsArray[index] = {...section};
    }

    // console.log("updated newSectionsArray: ", sectionsArray);
    console.log("updated sectionsArray: ", sectionsArray);
  };

  const checkFormHeaders = () => {
    let headerCheck = true;
    Object.values(formHeaders).forEach((value)=>{
      if(value===""){
        headerCheck =  false;
      };
    });
    return headerCheck;
  };

  const handleSubmitForm = async () => {
    console.log("submitted sections are: ", sectionsArray);
    if(!checkFormHeaders()){
      alert('Headers can not be empty');
    } else {
      const formToSubmit = generateFormForSubmission();
      if (formToSubmit !== false) {
        const formObject = {
          displayName: formHeaders.ID,
          sections: formToSubmit
        };
        console.log("formToSubmit: ", formToSubmit);
        if (validationService.hasEmptyKeys(formToSubmit)) {
          alert('Form keys can not be empty!');
        } else {
          try {
            const response = await apiGatewayService.postFormsData(formObject);
            console.log(response);
            if (handleClose) {
              handleClose();
            }
          }
          catch (error) {
            console.log("Error in submitting form: ", error);
          }
        }
      }

    }
  };

  const generateDisplayName = () => {

  };

  const handleAddHeader = () => {
    console.log("Adding a new header!!!!!");
    openModal();
  };

  const keys = Object.keys(formHeaders);

  // Adding new values for add headers--------------------------------------------------------------------------------------------------
  const [isOpen, setIsOpen] = useState(false);
  
  const [inputValue, setInputValue] = useState('');

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSectionSelectorOpen(false);
  };

  const saveModalValue = () => {
    if(inputValue!==""){
        const storedValue = inputValue;
        formHeaders[storedValue] = '';
    };
    setInputValue("");
    closeModal();
  };
  //-------------------------------------------------------------------------------------------------------------------

  const handleHeaderInputChange = (e) => {
    console.log("key: ", e.target.name);
    console.log("value: ", e.target.value);
    formHeaders[e.target.name] = e.target.value;
    setFormHeaders({...formHeaders});
    console.log("formHeaders: ", formHeaders);
  };

  const generateFormForSubmission = () => {
    if ((submitAPI!=='' || submitAPI!=='default') && isSubmissionURL ) {
      const isValid = isValidUrl(submitAPI);
      if (!isValid){
        alert("Please enter valid url for submission!");
        return false
      }
    };

    if((emailToSend!=='' && isEmail)){
      const isValidEmail = validateEmail(emailToSend);
      console.log('isValidEmail: ', isValidEmail);
      if (!isValidEmail){
        alert("Please enter valid email address for sending printed forms!");
        return false
      }
    };

    const date = formatDate(new Date());
    formHeaders['configMetaData'] = {
      CreatedOn: date,
      CreatedBy: cogntioUser.attributes.email.toUpperCase(),
      LastEditedOn: date,
      LastEditedBy: cogntioUser.attributes.email.toUpperCase(),
      Status: "Active"
    };;
    const formToSubmit = {
      formHeaders: formHeaders,
      sections: sectionsArray,
      // submitApi: submitAPI ? submitAPI : 'default', 
      submitApi:  submitAPI === "" ? getDefaultSubmitApi() : submitAPI
    };
    // For now only email in fure add further advance features
    if(isEmail){
      formToSubmit['isEmail'] = isEmail;
    };
    if(emailToSend!=='' && isEmail){
      formToSubmit['emailToSend'] = emailToSend;
    };

    if(isNew) {
      formToSubmit['publishStatus'] = 'saved';
    }
    return formToSubmit;
  };
  
  // Use this later on
  const getDefaultSubmitApi = () => {
    return `${AWS_API}/saveForms/${formHeaders.ID}`
  };

  function formatDate(date) {
    const pad = (number, length = 2) => String(number).padStart(length, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = pad(date.getMilliseconds(), 3) + '000000';
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  const addOptionsIntoField = (section) => {
    console.log("HERE INSIDE ADD OPTIONS:            ", section);
    if(section===undefined){
      handleAddSection();
    }
    else {
        console.log("sections Array before: ", sectionsArray);
        console.log("section from modal: ", section)
        sectionsArray.push(section);
        const newFieldsArray = Array.from(sectionsArray);
        setSectionsArray(newFieldsArray);
        console.log("updated sections array: ", sectionsArray);
    };
};

const updateSubmitURL = (event) => {
  setSubmitAPI(event.target.value);
};
  
const handleAddSubmitURL = (value) => {
  setIsSubmissionURL(value);
  if(!value){
    setSubmitAPI('');
  }
};


  
const handleAddAdvanceFeaturesL = (value) => {
  if(value){
    setIsAdvanceFeatures(true);
  } else{
    setIsAdvanceFeatures(false);
  }
};
function isValidUrl(url) {
  const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
  return urlPattern.test(url);
};

const submitSelectedAdvanceFeature = (value) => {


};

const updateEmailToSend = (email) => {
  console.log('email: ', email);
  setEmailToSend(email.target.value);
};

function validateEmail(email) {
  console.log('email to validate: ', email)
  // Regular expression for validating an email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the regex
  return emailRegex.test(email);
};

const handleMoveSectionUp = (index) => {
  console.log("index to move up: ", index);
  if (index > 0) {
      const newFieldsArray = [...sectionsArray];
      [newFieldsArray[index - 1], newFieldsArray[index]] = [newFieldsArray[index], newFieldsArray[index - 1]];
      setSectionsArray(newFieldsArray);
  }
};

const handleMoveSectionDown = (index) => {
  console.log("index to move down: ", index);
  if (index < sectionsArray.length - 1) {
      const newFieldsArray = [...sectionsArray];
      [newFieldsArray[index + 1], newFieldsArray[index]] = [newFieldsArray[index], newFieldsArray[index + 1]];
      setSectionsArray(newFieldsArray);
  }
};

const handleOpenSectionSelector = () => {
  setSectionSelectorOpen(true);
};


  return (
    <div className={classes.FormSectionsContainer}>
      <div className={classes.HeaderContainer}><h2 style={ {padding: '0px',margin: '0px'}}>Form Builder</h2></div>
      
      <div>
      <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Headers
      </Typography>
        <Modal isOpen={isOpen} closeModal={closeModal} saveValue = {saveModalValue} inputValue = {inputValue} setInputValue = {setInputValue}  />
        <div className={classes.FormFieldContainer}>
        {keys.map((key, index) => (
          <div className={ key==='configMetaData' ? classes.FieldEmpty :  classes.Field  } key={index}>
            {key === 'configMetaData' ? (
              <div> 
              </div>
            ) : (
              <div> 
              {/* <label>{key}</label> */}
              <InputLabel sx ={{mt:1,mb:1}}>{key}</InputLabel>
              {/* <input
                className={classes.Input}
                type="text"
                name={key}
                value={formHeaders[key]}
                onChange={handleHeaderInputChange}
                // onMouseOut={handleHeaderMouseOut}
              /> */}
              <TextField
                type="text"
                name={key}
                value={formHeaders[key]}
                onChange={handleHeaderInputChange}
                // onBlur={handleHeaderMouseOut} // onBlur is equivalent to onMouseOut
                variant="outlined"
                size="medium"
                sx={{
                  width: '100%',
                  "& .MuiInputBase-root": {
                    height: 40,
                    backgroundColor: 'white'
                  },
                  "& .MuiOutlinedInput-input": {
                    height: 40,
                    width: '100%',
                  },
                }}
              />
              </div>
            )}
          </div>
        ))}
          {/* <button className={classes.HeaderButton} onClick={handleAddHeader}>Add Header</button> */}
          <Tooltip title="Add More Headers">
          <Button
              onClick={handleAddHeader}
              sx={{
                mt: '35px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 0,
                background: 'transparent',
                color: 'var(--button-color)',
                cursor: 'pointer',
                borderRadius: '10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: 'var(--button-color)',
                },
                height: '58px'
              }}
            >
              <AddIcon sx={{ fontSize: 45, marginTop: 1, marginBottom: '5px', color: 'var--button-color)', padding: 0 }} />
              {/* <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Add Header</Typography> */}
            </Button> 
            </Tooltip>
        </div>
      </div>

    <FormControl component="fieldset">
      <FormGroup>
        {advanceFeatures.map((option) => (
          <Box key={option.id} sx={{ marginBottom: 0, marginLeft: '0px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedOptions[option.id] || false}
                    onChange={handleAdvanceFeatureSelection}
                    name={option.id}
                  />
                }
                label={option.name}
                sx={{ marginRight: 1 }}
              />
            </Box>
            {selectedOptions[option.id] && (
              <Box sx={{ marginTop: 0 }}>
                {['email'].includes(option.id) && (
                  <TextField
                    type="email"
                    name={option.id}
                    placeholder={option.placeHolderText}
                    onChange={updateEmailToSend}
                    value={emailToSend}
                    variant="outlined"
                    size="medium"
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                )}
                {['submit'].includes(option.id) && (
                  <TextField
                    type="text"
                    name={option.id}
                    placeholder={option.placeHolderText}
                    onChange={updateSubmitURL}
                    value={submitAPI}
                    variant="outlined"
                    size="medium"
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                )}
                {['pdf'].includes(option.id) && (
                  <div>
                    {/* Add PDF specific inputs or components here */}
                  </div>
                )}
                {!['email', 'submit', 'pdf'].includes(option.id) && (
                  <TextField
                    name={option.id}
                    // value={inputValues[option.id] || ''}
                    // onChange={handleInputChange}
                    placeholder={`Enter value for ${option.name}`}
                    sx={{ width: '100%' }}
                  />
                )}
              </Box>
            )}
          </Box>
        ))}
      </FormGroup>
    </FormControl>

      <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Sections
      </Typography>
      {sectionsArray.map((section, index) => (
        <div className={classes.fieldContainer}>
                          <div className={classes.ButtonContainerDrag}>
                            <Tooltip title="Move Up">
                            <Button
                                onClick={()=> handleMoveSectionUp(index)}
                                title="Move this field up"
                                // variant="contained"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: 0,
                                    background: 'transparent',
                                    color: 'var(--app-color)',
                                    cursor: 'pointer',
                                    borderRadius: '10px',
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                        color: 'var(--button-hover-colo)',
                                    },
                                    height: '30px'
                                }}
                            >
                            <KeyboardDoubleArrowUpIcon sx={{ fontSize: 30, marginTop: 0, marginBottom: '0px', color: 'var(--app-color)' }}/>
                            </Button>
                            </Tooltip>
                            <Tooltip title="Move Down">
                            <Button
                                onClick={()=>handleMoveSectionDown(index)}
                                // variant="contained"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: 0,
                                    background: 'transparent',
                                    color: 'var(--app-color)',
                                    cursor: 'pointer',
                                    borderRadius: '10px',
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                        color: 'var(--button-hover-colo)',
                                    },
                                    height: '30px'
                                }}
                            ><KeyboardDoubleArrowDownIcon sx={{ fontSize: 30, marginTop: 0, marginBottom: '0px', color: 'var(--app-color)' }}/>
                            </Button>
                            </Tooltip>
                        </div>
        <SectionBuilder 
           key={index} 
           indexSectionsArray={index}
           section ={{...section}} 
           updateSectionsArray={updateSectionsArray}
           title = {section.title}
           isForm = {true}
        />

        </div>
      ))}
      {/* {!isOnlyReadAcess &&
        <SelectionModalComponent
          type={'Section'}
          Builder={SectionBuilder}
          Selector={SectionsSelector}
          addOptionsIntoField={addOptionsIntoField}
        > </SelectionModalComponent>       
      } */}

      <div className={classes.SectionsButtonContainer}>
        <Button
          onClick={handleAddSection}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 0,
            background: 'transparent',
            color: 'var(--icon-button-color-grey)',
            cursor: 'pointer',
            borderRadius: '10px',
            ':hover': {
              backgroundColor: '#F0F4F4',
              color: 'var(--icon-button-color-grey)',
            },
            height: '58px'
          }}
        >
          <AddBoxIcon sx={{ fontSize: 32, marginTop: 1, marginBottom: '5px', color: 'var(--icon-button-color-grey)', padding: 0 }} />
          <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Add Section</Typography>
        </Button>
        <Button
          onClick={handleOpenSectionSelector}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 0,
            background: 'transparent',
            color: 'var(--icon-button-color-grey)',
            cursor: 'pointer',
            borderRadius: '10px',
            ':hover': {
              backgroundColor: '#F0F4F4',
              color: 'var(--icon-button-color-grey)',
            },
            height: '58px'
          }}
        >
          <ReorderIcon sx={{ fontSize: 32, marginTop: 1, marginBottom: '5px', color: 'var(--icon-button-color-grey)', padding: 0 }} />
          <Typography sx={{ fontSize: 10, textTransform: 'none' }} variant="body2">Select Section</Typography>
        </Button>
      </div>

      {
        !isOnlyReadAcess &&
        <SectionsSelector
        isOpen={sectionSelectorOpen}
        handleClose={closeModal}
        submitOption={addOptionsIntoField}
      />
      }
      {!isOnlyReadAcess && <button className={classes.Button} onClick={handleSubmitForm}>Save Form</button>}

    </div>
  );
};

export default FormSectionsBuilder;