import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Navigation from './Navigation';
import { validateField } from './components/validate'; 
import './css/FormStyles.css'; // Update the path according to your file structure
import classes from './FormFilled.module.css';
import Select from 'react-select';
import SectionFilled from './modules/SectionFilled/SectionFilled';
import apiGatewayService from './common/services/apiGatewayService';
import localStorageService from './common/services/localStorage.service';
import utilityFunctions from './common/services/utilityFunctions';
import CircularLoader from './modules/Loader/Loader';


const FormFilled = (props) => {
    const [formConfig, setFormConfig] = useState({ sections: [] });
    const [dropdownOptions, setDropdownOptions] = useState({});
    const [fieldValues, setFieldValues] = useState({});
    const [activeSectionIndex, setActiveSectionIndex] = useState(0);
    const [formId, setFormId] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');
    const [errors, setErrors] = useState({});
    const [isFromBuilder, setIsFromBuilder] = useState(false);
    const [logo, setLogo] = useState(null);
    const [logoConfig, setLogoConfig] = useState(null);
    const [allTableInputsData, setAllTableInputsData] = useState({});
    const [allSignatureInputsData, setAllSignatureInputsData] = useState({});
    const [allAdditionalInputsData, setAllAdditionalInputsData] = useState({});
    const [submittedFields, setSubmittedFields] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [valueHelpsTypeMapping, setValueHelpsTypeMapping ] = useState({

        'options': 'advance',

        'optionsSimple': 'simple',

        'optionsApi': 'api'

    });

    
    // Style customization for react-select
    const customStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: isFocused ? 'lightgray' : 'white',
            color: 'black',
        }),
    };

    // useEffect(() => {
    //     const formIdFromUrl = window.location.hash.substring(1);
    //     console.log('formIdFromUrl:  ', formIdFromUrl);
    //     setFormId(formIdFromUrl);
    //     const apiUrl = `https://zspe1uaat0.execute-api.ap-southeast-2.amazonaws.com/form/${formIdFromUrl}`;
    //     axios.get(apiUrl).then(response => {
    //         setFormConfig(response.data);
    //         initializeFieldValues(response.data.sections);
    //         fetchDropdownData(response.data.sections);
    //     }).catch(error => {
    //         console.error('Failed to fetch form config:', error);
    //     });
    // }, []);

    useEffect(() => {
        const getSubmittedFormsDataById = async () => {
            const response = await apiGatewayService.getSubmittedFormsById(props.formId);
            const dataWithUpdatedKeyAndContent = utilityFunctions.getKeyAndContentSubmittedForms(response.data);
            console.log("response data updated: ", dataWithUpdatedKeyAndContent);
            return dataWithUpdatedKeyAndContent;
            // setForms(dataWithUpdatedKeyAndContent);
            // setIsLoading(false);
        };
        const fetchData = async () => {
            try {
                setIsLoading(false);
                const isForm = localStorageService.getObject('isCurrForm');
                console.log("isForm: ", isForm);
                console.log("displayFromReader: ", props.displayFromReader);
                console.log("window.location: ", window.location);

                // if (window.location.pathname.includes('displayPublicForms')) {
                //     localStorageService.setObject('isCurrForm', 'false');
                //     const formIdFromUrl = window.location.hash.substring(1);
                //     console.log('formIdFromUrl: ', formIdFromUrl);
                //     const formId = formIdFromUrl;
                //     const response = await apiGatewayService.getFormConfiguration(decodeURIComponent(formId));
                //     console.log("This is the fetched form: ", response);
                //     setIsFromBuilder(true);
                //     setFormConfig(response.data);
                //     initializeFieldValues(response.data.sections);
                //     await fetchDropdownData(response.data.sections);
                // }   
                // else if(window.location.pathname.includes('displayForms2')) {
                //     localStorageService.setObject('isCurrForm', 'true');
                //     const formIdFromUrl = window.location.hash.substring(1);
                //     console.log('formIdFromUrl: ', formIdFromUrl);
                //     const formId = formIdFromUrl;
                //     const response = await apiGatewayService.getFormById(formId);
                //     console.log("This is the fetched form: ", response);
                //     setIsFromBuilder(true);
                //     setFormConfig(response.data);
                //     initializeFieldValues(response.data.sections);
                //     await fetchDropdownData(response.data.sections);
                // }              
                if (props.displayFromReader) {
                        localStorageService.setObject('isCurrForm', 'true');
                        // const formIdFromUrl = window.location.hash.substring(1);
                        // const submittedFormId = window.location.hash.substring(2);
                        const temp = window.location.pathname.split('/');
                        const formIdFromUrl = temp[temp.length - 2];
                        const submittedFormId = temp[temp.length - 1];
                        console.log('formIdFromUrl: ', formIdFromUrl);
                        console.log('submittedFormId: ', submittedFormId);
                        // const formId = localStorageService.getObject('formId');
                        const submittedForm = await apiGatewayService.getSubmittedFormsByIdAndSubmittedFormId(formIdFromUrl, submittedFormId);
                        console.log("submittedForm: ", submittedForm);
                        const fields = submittedForm.data;
                        const response = await apiGatewayService.getFormById(formIdFromUrl);
                        console.log("This is the fetched form: ", response);
                        // const fields = localStorageService.getObject('submittedFields');
                        setSubmittedFields(fields);
                        setIsFromBuilder(true);
                        setFormConfig(response.data);
                        initializeFieldValues(response.data.sections);
                        await fetchDropdownData(response.data.sections);
                } else {
                    localStorageService.setObject('isCurrForm', 'true');
                    // const formIdFromUrl = window.location.hash.substring(1);
                    // const submittedFormId = window.location.hash.substring(2);
                    const temp = window.location.pathname.split('/');
                    const formIdFromUrl = temp[temp.length - 3];
                    const submittedFormId = temp[temp.length - 2];
                    const token = temp[temp.length - 1];
                    localStorageService.setObject('directToken', token );
                    console.log('formIdFromUrl: ', formIdFromUrl);
                    console.log('submittedFormId: ', submittedFormId);
                    console.log('token: ', token);
                    // const formId = localStorageService.getObject('formId');
                    const submittedForm = await apiGatewayService.getSubmittedFormsByIdAndSubmittedFormId(formIdFromUrl, submittedFormId);
                    console.log("submittedForm: ", submittedForm);
                    const fields = submittedForm.data;
                    const response = await apiGatewayService.getFormById(formIdFromUrl);
                    console.log("This is the fetched form: ", response);
                    // const fields = localStorageService.getObject('submittedFields');
                    setSubmittedFields(fields);
                    setIsFromBuilder(true);
                    setFormConfig(response.data);
                    initializeFieldValues(response.data.sections);
                    await fetchDropdownData(response.data.sections);
                }
            
            } catch (error) {
                console.error('Failed to fetch form config:', error);
            }
            setIsLoading(false);
        };

        const getCompanyLogo = async () => {
            setIsLoading(true);
            try {
              const response = await apiGatewayService.getCompanyLogo();
              const logoUrl = response.data.url;
              if(logoUrl!==null) {
                setLogo(logoUrl);
                setLogoConfig(response.data.logoConfig);
              };
            } catch (error) {
              console.error('Error getting company logo:', error);
            }
            setIsLoading(false);
        };


        fetchData();
        getCompanyLogo();
    }, [props]);
    
    
    // useEffect(() => {
    
    //     // Effect to update dropdowns based on dependencies
    //     formConfig.sections.forEach((section, sectionIndex) => {
    //         section.fields.forEach(field => {
    //             if (field.api && field.api.dependencies) {
    //                 field.api.dependencies.forEach(dep => {
    //                     const depSection = formConfig.sections.find(sec => sec.title === dep.sectionName);
    //                     const depSectionIndex = formConfig.sections.indexOf(depSection);
    //                     const depFieldKey = `${depSectionIndex}-${dep.fieldName}`;

                        
    //                         if (fieldValues[depFieldKey]) {  // Check if dependency is not empty
    //                             fetchSpecificDropdownData(formConfig.sections, `${sectionIndex}-${field.name}`);
    //                         }
                        
    //                 });
    //             }
    //         });
    //     });
    // }, [fieldValues, formConfig.sections]); // React to changes in field values and the sections of the form configuration
    

    const initializeFieldValues = (sections) => {
        const initialFieldValues = {};
        sections.forEach((section, sectionIndex) => {
            section.fields.forEach(field => {
                const fieldKey = `${sectionIndex}-${field.name}`;
                initialFieldValues[fieldKey] = field.api?.default || '';
            });
        });
        setFieldValues(initialFieldValues);
    };

    const Navigation = ({ sections, onSectionClick, activeSectionIndex }) => {
        return (
            <div className={classes.Navigation}>
                {sections.map((section, index) => (
                    <button
                        key={index}
                        // className={`tab-button ${index === activeSectionIndex ? 'active' : ''}`}
                        className={classes.TabButton}
                        onClick={() => onSectionClick(index)}
                    >
                        {section.title}
                    </button>
                ))}
            </div>
        );
    };



    const onSectionClick = (index) => {
        setActiveSectionIndex(index);

        // Scroll to the section
        const sectionElement = document.getElementById(`section-${index}`);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

            // Set focus to the first input or select element within the section
            const firstInput = sectionElement.querySelector('input, select');
            if (firstInput) {
                firstInput.focus();
            }
        }
    };

    const resetDropdown = (sectionIndex, fieldName) => {
        const fieldKey = `${sectionIndex}-${fieldName}`;
        const fieldConfig = formConfig.sections[sectionIndex].fields.find(field => field.name === fieldName);
    
        if (fieldConfig && fieldConfig.api.default) {
            // Set field value to default if specified
            setFieldValues(prevValues => ({
                ...prevValues,
                [fieldKey]: fieldConfig.api.default
            }));
        } else {
            // Otherwise, reset the dropdown options and set value to empty
            setDropdownOptions(prevOptions => ({
                ...prevOptions,
                [fieldKey]: [{label: '', value: ''}]  // Clear the options
            }));
            setFieldValues(prevValues => ({
                ...prevValues,
                [fieldKey]: ''
            }));
        }
    };
    

    // const fetchDropdownData = (sections) => {
    //     sections.forEach((section, sectionIndex) => {
    //         section.fields.forEach(field => {
    //             if (['dropdown', 'selection'].includes(field.type) && field.api) {
    //                 const { url, label, value, displayPattern = "{label} - {value}", default: defaultValue } = field.api;
    //                 const fieldKey = `${sectionIndex}-${field.name}`;

    //                 let baseUrl = url;

    //                 // Handle dependencies to format URL as required
    //                 if (field.api.dependencies) {
    //                     const dependencyFilters = Object.entries(field.api.dependencies).map(([key, placeholder]) => {
    //                         const dependentFieldValue = fieldValues[`${sectionIndex}-${key}`]; // Assume key is the field name of dependency
    //                         return `${placeholder}='${encodeURIComponent(dependentFieldValue)}'`;
    //                     }).join(',');

    //                     baseUrl += `(${dependencyFilters})`; // Append formatted filters directly to the URL
    //                 }
    //                 console.log(baseUrl);  // Debugging to see what is being stored

                        
    //                 axios.get(baseUrl)
    //                     .then(response => {
    //                         const options = response.data.map(item => {
    //                             // Check if we're dealing with simple string array ('self' option)
    //                             if (label === 'self' && value === 'self') {
    //                                 return {
    //                                     label: item,  // Use item directly for both label and value
    //                                     value: item,
    //                                     additionalText: item.VALID_VALUE_ADDITIONAL_TEXT || ""  // Assume additional text might be directly in the item
    //                                 };
    //                             } else {
    //                                 return {
    //                                     label: displayPattern.replace('{label}', item[label]).replace('{value}', item[value]),
    //                                     value: item[value],
    //                                     additionalText: item.VALID_VALUE_ADDITIONAL_TEXT || "",
    //                                     srcLabel: item[label]
    //                                 };
    //                             }
    //                         });

    //                         console.log(options);  // Debugging to see what is being stored

    //                         if (!defaultValue) {
    //                             // Use placeholder text from config or default to a generic placeholder
    //                             const placeholder = field.placeholdertext || ""; //Select an option
    //                             options.unshift({ label: placeholder, value: "" });  // Prepend the placeholder option

    //                         }

                            
    //                         setDropdownOptions(prevOptions => ({
    //                             ...prevOptions,
    //                             [fieldKey]: options
    //                         }));

    //                         setFieldValues(prev => ({
    //                             ...prev,
    //                             [fieldKey]: defaultValue || ''
    //                         }));
    //                     })
    //                     .catch(error => {
    //                         console.error('Failed to fetch dropdown options:', error);
    //                     });
    //             }
    //         });
    //     });
    // };



    // const fetchSpecificDropdownData = (sections, specificFieldKey = null, newValue) => {
    //     sections.forEach((section, sectionIndex) => {
    //         section.fields.forEach(field => {
    //             const fieldKey = `${sectionIndex}-${field.name}`;
    //             if (field.type === 'dropdown' || field.type === 'selection') {
    //                 if (!specificFieldKey || specificFieldKey === fieldKey) {
    //                     let apiUrl = field.api.url;
    //                     if (field.api.dependencies) {
    //                         const filters = field.api.dependencies.map(dep => {
    //                             const depSection = sections.find(sec => sec.title === dep.sectionName);
    //                             const depSectionIndex = sections.indexOf(depSection);
    //                             const depFieldKey = `${depSectionIndex}-${dep.fieldName}`;
    //                             //const depValue = fieldValues[depFieldKey];
    
    //                             // Retrieve the selected option based on the stored value
    //                             const selectedOption = dropdownOptions[depFieldKey]?.find(option => option.value === newValue);
    
    //                             // Determine the filter value based on the configuration
    //                             let filterValue = '';
    //                             if (dep.filterValue === 'label' && selectedOption) {
    //                                 filterValue = encodeURIComponent(selectedOption.srcLabel);
    //                             } else if (dep.filterValue === 'value') {
    //                                 filterValue = encodeURIComponent(newValue);
    //                             }
    
    //                             return `${dep.filterField}='${filterValue}'`;
    //                         }).filter(f => f.includes("'")).join(',');
    
    //                         apiUrl += `(${filters})`;
    //                     }
    
    //                     axios.get(apiUrl)
    //                         .then(response => {
    //                             const options = response.data.map(item => ({
    //                                 label: field.api.displayPattern.replace('{label}', item[field.api.label]).replace('{value}', item[field.api.value]),
    //                                 value: item[field.api.value],
    //                                 additionalText: item.VALID_VALUE_ADDITIONAL_TEXT || "",
    //                                 srcLabel: item[field.api.label]
    //                             }));
    
    //                             if (!field.api.default) {
    //                                 const placeholder = field.placeholdertext || ""; //Select an option
    //                                 options.unshift({ label: placeholder, value: "" });
    //                             }
    
    //                             setDropdownOptions(prevOptions => ({
    //                                 ...prevOptions,
    //                                 [fieldKey]: options
    //                             }));
    
    //                             if (field.api.default) {
    //                                 setFieldValues(prev => ({
    //                                     ...prev,
    //                                     [fieldKey]: field.api.default
    //                                 }));
    //                             }
    //                         })
    //                         .catch(error => {
    //                             console.error(`Failed to fetch dropdown options for ${field.name}:`, error);
    //                         });
    //                 }
    //             }
    //         });
    //     });
    // };
    
    const fetchSpecificDropdownData = async (sections, specificFieldKey = null, newValue) => {
        try {
            for (const [sectionIndex, section] of sections.entries()) {
                for (const field of section.fields) {
                    const fieldKey = `${sectionIndex}-${field.name}`;
                    if (field.type === 'dropdown' || field.type === 'selection') {
                        if (!specificFieldKey || specificFieldKey === fieldKey) {
                            let apiUrl = field.api.url;
                            if (field.api.valueHelpFilters) {
                                const filters = field.api.valueHelpFilters.map(dep => {
                                    const depSection = sections.find(sec => sec.title === dep.referenceSectionName);
                                    const depSectionIndex = sections.indexOf(depSection);
                                    const depFieldKey = `${depSectionIndex}-${dep.referenceField}`;
                                    const selectedOption = dropdownOptions[depFieldKey]?.find(option => option.value === newValue);
                                    let filterValue = '';
                                    if (dep.matchingValue && dep.matchingValue === newValue) {
                                        filterValue = encodeURIComponent(dep.staticValue);
                                    } else {
                                        if (dep.referenceFieldValueHelpKey === 'label' && selectedOption) {
                                            filterValue = encodeURIComponent(selectedOption.srcLabel);
                                        } else if (dep.valueHelpFilter === 'value') {
                                            filterValue = encodeURIComponent(newValue);
                                        }
                                    }

                                    return `${dep.valueHelpFilter}='${filterValue}'`;
                                }).filter(f => f.includes("'")).join(',');
                                apiUrl += `(${filters})`;
                            }
    
                            let response;
                            const isForm = localStorageService.getObject('isCurrForm');
                            const isPublic = localStorageService.getObject('isPublic');
                            const type = valueHelpsTypeMapping[apiUrl.split('/')[0]];
                            console.log('api url type: ', type);
                            if (isPublic === 'true'){
                                response = await apiGatewayService.getValueHelpsDataPublic(`${apiUrl.split('/')[1]}`, type);
                            }
                            else if(isForm === 'true'){
                                // response = await apiGatewayService.getValueHelpsData(cognitoUser, `${baseUrl}.json`);
                                response = await apiGatewayService.getValueHelpsData( `${apiUrl.split('/')[1]}`, type);
                            } else {
                                response = await apiGatewayService.getDropDownData(apiUrl);
                            }
                            const options = response.data.map(item => ({
                                label: field.api.displayPattern.replace('{label}', item[field.api.label]).replace('{value}', item[field.api.value]),
                                value: item[field.api.value],
                                additionalText: item.VALID_VALUE_ADDITIONAL_TEXT || "",
                                srcLabel: item[field.api.label]
                            }));
    
                            if (!field.api.default) {
                                const placeholder = field.placeholdertext || ""; //Select an option
                                options.unshift({ label: placeholder, value: "" });
                            }
    
                            setDropdownOptions(prevOptions => ({
                                ...prevOptions,
                                [fieldKey]: options
                            }));
    
                            if (field.api.default) {
                                setFieldValues(prev => ({
                                    ...prev,
                                    [fieldKey]: field.api.default
                                }));
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Failed to fetch dropdown options:', error);
        }
    };
    
    const adjustUrlWithDependencies = (url, dependencies, sectionIndex) => {
        if (!dependencies) return url;
        let filters = '';
        Object.entries(dependencies).forEach(([depField, filterName], index) => {
            const depFieldKey = `${sectionIndex}-${depField}`;
            const depValue = fieldValues[depFieldKey];
            if (depValue) {
                filters += `${index > 0 ? ',' : ''}${filterName}='${encodeURIComponent(depValue)}'`;
            }
        });
    
        return filters ? `${url}(${filters})` : url;
    };
    

    // // Ensure to call this function whenever dependencies change
    // useEffect(() => {
    //     fetchDropdownData(formConfig.sections);
    // }, [fieldValues]); // You might list specific fields if needed to minimize unnecessary fetches

    const fetchDropdownData = async (sections) => {
        try {
            for (const [sectionIndex, section] of sections.entries()) {
                for (const field of section.fields) {
                    if (['dropdown', 'selection'].includes(field.type) && field.api) {
                        const { url, label, value, displayPattern = "{label} - {value}", default: defaultValue, valueHelpFilters } = field.api;
                        const fieldKey = `${sectionIndex}-${field.name}`;

                        let baseUrl = url;

                        // Handle dependencies to format URL as required
                        if (valueHelpFilters) {
                            const dependencyFilters = Object.entries(valueHelpFilters).map(([key, placeholder]) => {
                                const dependentFieldValue = fieldValues[`${sectionIndex}-${key}`]; // Assume key is the field name of dependency
                                return `${placeholder}='${encodeURIComponent(dependentFieldValue)}'`;
                            }).join(',');

                            baseUrl += `(${dependencyFilters})`; // Append formatted filters directly to the URL
                        }
                        console.log(baseUrl);  // Debugging to see what is being stored

                        // const response = await axios.get(baseUrl);
                        // const response = await apiGatewayService.getDropDownData(baseUrl);
                        let response;
                        const isForm = localStorageService.getObject('isCurrForm');
                        const isPublic = localStorageService.getObject('isPublic');
                        const type = valueHelpsTypeMapping[baseUrl.split('/')[0]];
                        console.log('api url type: ', type);
                        if (isPublic === 'true'){
                            response = await apiGatewayService.getValueHelpsDataPublic(`${baseUrl.split('/')[1]}`, type);
                        }
                        else if(isForm === 'true'){
                            // response = await apiGatewayService.getValueHelpsData(cognitoUser, `${baseUrl}.json`);
                            response = await apiGatewayService.getValueHelpsData( `${baseUrl.split('/')[1]}`, type);
                        } else {
                            response = await apiGatewayService.getDropDownData(baseUrl);
                        }
                        // console.log("Get Drop Down Response: ", response);
                        // console.log("Get Drop Down Response Section: ", section); 
                        // console.log("Get Drop Down Response label: ", label); 
                        // console.log("Get Drop Down Response value: ", value); 
                        const options = response.data.map(item => {
                            // Check if we're dealing with simple string array ('self' option)
                            if (label === 'self' && value === 'self') {
                                return {
                                    label: item,  // Use item directly for both label and value
                                    value: item,
                                    additionalText: item.VALID_VALUE_ADDITIONAL_TEXT || ""  // Assume additional text might be directly in the item
                                };
                            } else {
                                return {
                                    label: displayPattern.replace('{label}', item[label]).replace('{value}', item[value]),
                                    value: item[value],
                                    additionalText: item.VALID_VALUE_ADDITIONAL_TEXT || "",
                                    srcLabel: item[label]
                                };
                            }
                        });

                        console.log(options);  // Debugging to see what is being stored

                        if (!defaultValue) {
                            // Use placeholder text from config or default to a generic placeholder
                            const placeholder = field.placeholdertext || ""; //Select an option
                            options.unshift({ label: placeholder, value: "" });  // Prepend the placeholder option
                        }

                        setDropdownOptions(prevOptions => ({
                            ...prevOptions,
                            [fieldKey]: options
                        }));

                        setFieldValues(prev => ({
                            ...prev,
                            [fieldKey]: defaultValue || ''
                        }));
                    }
                }
            }
        } catch (error) {
            console.error('Failed to fetch dropdown options:', error);
        }
    };

    // Generalized input change handling logic
    // const handleInputChange = (value, sectionIndex, fieldName) => {
    //     const fieldKey = `${sectionIndex}-${fieldName}`; 
    //     setFieldValues(prev => ({
    //         ...prev,
    //         [fieldKey]: value
    //     }));
    
    //     let newErrors = {...errors};
    
    //     // Retrieve the field config to determine if validation is needed
    //     const fieldConfig = formConfig.sections[sectionIndex].fields.find(field => field.name === fieldName);
    
    //     if (fieldConfig.validation && !validateField(fieldName, value, fieldConfig.validation)) {
    //         newErrors[fieldKey] = `Invalid ${fieldConfig.label.toLowerCase()}`;
    //     } else {
    //         delete newErrors[fieldKey];
    //     }

    //     // Check if the changed field has dependents that need to be updated
    //     formConfig.sections.forEach((section, secIndex) => {
    //         section.fields.forEach(field => {
    //             if (field.api && field.api.dependencies) {
    //                 field.api.dependencies.forEach(dep => {
    //                     if (dep.fieldName === fieldName && dep.sectionName === formConfig.sections[sectionIndex].title) {
    //                         // // Fetch data for the dependent dropdown
    //                         const dependentFieldKey = `${secIndex}-${field.name}`;
    //                         console.log(fieldValues[dependentFieldKey])
    //                         // Fetch new data only if the current value is available and different from the default
    //                         if (value !== (field.api.default || '')) {
    //                             fetchSpecificDropdownData(formConfig.sections,dependentFieldKey,value);
    //                         } else {
    //                             resetDropdown(secIndex, field.name);
    //                         }
    //                     }
    //                 });
    //             }
    //         });
    //     });

    //     setErrors(newErrors);
    // };

    const handleInputChange = async (value, sectionIndex, fieldName) => {
        const fieldKey = `${sectionIndex}-${fieldName}`;
        const newFieldValues = {
            ...fieldValues,
            [fieldKey]: value
        };
        console.log("newFieldValues: ", newFieldValues);
        setFieldValues(newFieldValues);
        let newErrors = { ...errors };
    
        // Retrieve the field config to determine if validation is needed
        const fieldConfig = formConfig.sections[sectionIndex].fields.find(field => field.name === fieldName);
    
        if (fieldConfig?.validation && !validateField(fieldName, value, fieldConfig.validation)) {
            newErrors[fieldKey] = `Invalid ${fieldConfig.label.toLowerCase()}`;
        } else {
            delete newErrors[fieldKey];
        }
    
        // Check if the changed field has dependents that need to be updated
        for (const [secIndex, section] of formConfig.sections.entries()) {
            for (const field of section.fields) {
                if (field.api && field.api.valueHelpFilters) {
                    for (const dep of field.api.valueHelpFilters) {
                        if (dep.referenceField === fieldName && dep.referenceSectionName === formConfig.sections[sectionIndex].title) {
                            // Fetch data for the dependent dropdown
                            const dependentFieldKey = `${secIndex}-${field.name}`;
                            console.log("dependentFieldKey: ", fieldValues[dependentFieldKey]);
                            // Fetch new data only if the current value is available and different from the default
                            if (value !== (field.api.default || '')) {
                                await fetchSpecificDropdownData(formConfig.sections, dependentFieldKey, value);
                            } else {
                                resetDropdown(secIndex, field.name);
                            }
                        }
                    }
                }
            }
        }
    
        setErrors(newErrors);
    };
    
    
    // Handles changes from native inputs (text, textarea, native select)
    const handleNativeInputChange = async (event, sectionIndex) => {
        const { name, value } = event.target;
        console.log("Inside native input change");
        console.log("name: ", name);
        console.log("value: ", value);
        await handleInputChange(value, sectionIndex, name);
    };

    const handleNativeInputChangeTableData = async (event, sectionIndex) => {
        const { name, value } = event.target;
        console.log("Inside native input change table data");
        console.log("name: ", name);
        console.log("value: ", value);
        setAllTableInputsData({...allTableInputsData, [name]: value});
        await handleInputChange(value, sectionIndex, name);
    };
    
    const handleNativeInputChangeSignatureData = async (event, sectionIndex, required) => {
        const { name, value } = event.target;
        console.log("Inside native input change signature data");
        console.log("name: ", name);
        console.log("value: ", value);
        // allTableInputsData.push({[name]: value, required });
        console.log(allSignatureInputsData);
        const temp = {...allSignatureInputsData, [name]: {value, required, fieldKey: `${sectionIndex}-${name}`}};
        console.log("signature inputs data after updates: ",temp);
        setAllSignatureInputsData(temp);
        await handleInputChange(value, sectionIndex, name);
    };

    const handleNativeInputChangeAdditionalData = async (event, sectionIndex, required) => {
        const { name, value } = event.target;
        console.log("Inside native input change additional data");
        console.log("name: ", name);
        console.log("value: ", value);
        // allTableInputsData.push({[name]: value, required });
        console.log(allAdditionalInputsData);
        const temp = {...allAdditionalInputsData, [name]: {value, required, fieldKey: `${sectionIndex}-${name}`}};
        console.log("additional inputs data after updates: ",temp);
        setAllAdditionalInputsData(temp);
        await handleInputChange(value, sectionIndex, name);
    };
    
    

    // Handles changes from React-Select
    const handleSelectChange = async (selectedOption, sectionIndex, fieldName) => {
        // React-Select gives us the selected option object or null if cleared
        const value = selectedOption ? selectedOption.value : '';
        await handleInputChange(value, sectionIndex, fieldName);
    };


    // const handleSubmit = (event) => {
    //     event.preventDefault();  // Prevent the default form submission behavior
    
    //     // Extract the form data from the event
    //     const formData = new FormData(event.target);
    //     const formObj = Object.fromEntries(formData.entries());
    
    //     // Retrieve the submit API from the form configuration
    //     const submitUrl = formConfig.submitApi;
    
    //     // Check if the submit URL is available
    //     if (!submitUrl) {
    //         console.error('Submit API URL is not defined in the form configuration.');
    //         alert('Submission failed: No submit API defined.');
    //         return;
    //     }
    
    //     // Use Axios to post the form data to the configured submit API
    //     axios.post(submitUrl, formObj)
    //         .then(() => {
               
    
    //             // Check for redirect URL in the configuration
    //             const redirectUrl = formConfig.redirectURL;
    //             if (redirectUrl) {
    //                 // Redirect if URL is provided
    //                 //window.location.href = redirectUrl;
    //                 setRedirectURL(formConfig.redirectURL); // Store redirect URL
    //                 setShowModal(true); // Show modal on success
    //             } else {
    //                 // Reset the form if no redirect URL is specified
    //                 alert('FormFilled submitted successfully');
    //                 resetForm();
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error submitting form:', error);
    //             alert('Submission failed: ' + error.message);
    //         });
    // };
    
    // Function to reset the form fields to their initial state
    
    const handleSubmit = async (event) => {
        try {
            let checkSign = true;
            event.preventDefault();  // Prevent the default form submission behavior
    
            // Extract the form data from the event
            const formData = new FormData(event.target);
            let formObj = Object.fromEntries(formData.entries());

            if (Object.keys(allTableInputsData).length > 0) {
                formObj = {...formObj, ...allTableInputsData}
            };
            console.log("allSignatureInputsData: ", allSignatureInputsData);
            if (Object.keys(allSignatureInputsData).length > 0) {
                Object.keys(allSignatureInputsData).forEach((key)=>{
                    if(allSignatureInputsData[key].required && allSignatureInputsData[key]?.value===''){
                        const secIndex = allSignatureInputsData[key]?.fieldKey.split('-')[0];
                        document.getElementById(`section-${secIndex}`).scrollIntoView({ behavior: 'smooth' });
                        alert(`Please fill out ${key}`);
                        checkSign = false;
                        return true;
                    } else {
                        formObj = {...formObj, [key]: allSignatureInputsData[key].value}        
                    }
                })
            };
            debugger
            console.log("allAdditionalInputsData: ", allAdditionalInputsData);
            if (Object.keys(allAdditionalInputsData).length > 0) {
                Object.keys(allAdditionalInputsData).forEach((key)=>{
                    if(allAdditionalInputsData[key].required && allAdditionalInputsData[key]?.value===''){
                        const secIndex = allAdditionalInputsData[key]?.fieldKey.split('-')[0];
                        document.getElementById(`section-${secIndex}`).scrollIntoView({ behavior: 'smooth' });
                        alert(`Please fill out ${key}`);
                        checkSign = false;
                        return true;
                    } else {
                        formObj = {...formObj, [key]: allAdditionalInputsData[key].value}        
                    }
                });
            };


            if(!checkSign){
                return;
            }
            // Retrieve the submit API from the form configuration
            const submitUrl = formConfig.submitApi;
    
            // Check if the submit URL is available
            if (!submitUrl) {
                throw new Error('Submit API URL is not defined in the form configuration.');
            }
            
            console.log("This is the form object to submit: ", formObj);

            //uncomment this later when you actually have to submit the data    
            // // Use Axios to post the form data to the configured submit API
            // await axios.post(submitUrl, formObj);
            if (isFromBuilder) {
                await apiGatewayService.saveForms(submitUrl, formObj);
            } else {
                await apiGatewayService.postFormData(submitUrl, formObj);
            }

    
            // Check for redirect URL in the configuration
            const redirectUrl = formConfig.redirectURL;
            if (redirectUrl) {
                // Redirect if URL is provided
                setRedirectURL(formConfig.redirectURL); // Store redirect URL
                setShowModal(true); // Show modal on success
            } else {
                // Reset the form if no redirect URL is specified
                alert('FormFilled submitted successfully');
                resetForm();
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Submission failed: ' + error.message);
        }
    };    
    
    const resetForm = () => {
        // Reset field values to initial (empty or default) state
        const initialFieldValues = {};
        formConfig.sections.forEach((section, sectionIndex) => {
            section.fields.forEach(field => {
                const fieldKey = `${sectionIndex}-${field.name}`;
                initialFieldValues[fieldKey] = field.api?.default || '';
            });
        });
        setFieldValues(initialFieldValues);
        // Optionally reset other state like errors, etc.
        setErrors({});
    };
    
    

    const determineVisibility = (field, sectionIndex) => {
        if (!field.dependant) {
            return true; // Show the field unless explicitly hidden
        }
        const dependencyFieldKey = `${sectionIndex}-${field.dependant.field}`;
        const dependencyMet = fieldValues[dependencyFieldKey];
        return dependencyMet && dependencyMet.length > 0;
    };

    const getAlignment = (position) => {
        switch (position) {
            case 'left':
                return 'flex-start';
            case 'center':
                return 'center';
            case 'right':
                return 'flex-end';
            default:
                return 'center';
        }
    };

    return (
        <div>
        { isLoading &&  <CircularLoader></CircularLoader>}    
        { !isLoading && <div>
        {/* <h2 style ={{marginLeft: '35px'}}> {formConfig?.formHeaders?.FormName}</h2> */}
        <div style={{ display: 'flex', justifyContent: getAlignment(logoConfig?.position) }}>
            <img
                src={logo}
                alt="Your Image Description"
                style={{
                    marginTop: '20px',
                    maxWidth: logoConfig?.width || '100%', // Ensures the image doesn’t exceed the specified width or parent container
                    maxHeight: logoConfig?.height || '100%', // Ensures the image doesn’t exceed the specified height or parent container
                    width: 'auto', // Allows the image to scale proportionally
                    height: 'auto', // Allows the image to scale proportionally
                    objectFit: 'contain', // Ensures the image fits within the specified dimensions
                    display: logoConfig?.isDisplayForm === false ? 'none' : '',
                }}
            />    
        </div>
        <div className={classes.FormTitle} >
        <h2 > {formConfig?.formHeaders?.FormName}</h2>
        </div>
            <Navigation
                className={classes.Navigation}
                sections={formConfig.sections}
                onSectionClick={onSectionClick}
                activeSectionIndex={activeSectionIndex}
            />
            <form onSubmit={handleSubmit} className={classes.FormContainer}>
                {formConfig.sections.map((section, sectionIndex) => (
                    <SectionFilled
                        key={sectionIndex}
                        section={section}
                        formConfig={formConfig}
                        errors={errors}
                        setFieldValues={setFieldValues}
                        sectionIndex={sectionIndex}
                        dropdownOptions={dropdownOptions}
                        fieldValues={fieldValues}
                        handleNativeInputChange={handleNativeInputChange}
                        determineVisibility={determineVisibility}
                        handleSelectChange={handleSelectChange}
                        handleNativeInputChangeTableData = {handleNativeInputChangeTableData}
                        handleNativeInputChangeSignatureData = {handleNativeInputChangeSignatureData}
                        handleNativeInputChangeAdditionalData = {handleNativeInputChangeAdditionalData}
                        disabled = {true}
                        submittedFields = {submittedFields}
                    />
                ))}
                {/* <button type="submit" className={classes.SubmitButton}>Submit</button> */}
            </form>
            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                contentLabel="Submission Success"
            >
                <h2>FormFilled Submitted Successfully</h2>
                <button onClick={() => {
                    window.location.href = redirectURL;
                }}>OK</button>
            </Modal>
        </div>}
      </div>

    );
};
export default FormFilled;
