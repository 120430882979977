import React, { useState, useEffect } from 'react';
import { Button, TextField, Box, Container, IconButton, Typography, Toolbar,
   AppBar, CircularProgress, FormControl, InputLabel, Select, MenuItem, 
   Checkbox, FormControlLabel , Grid} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import apiGatewayService from '../../common/services/apiGatewayService';
import utilityFunctions from '../../common/services/utilityFunctions';
import SettingsIcon from '@mui/icons-material/Settings';
import CircularLoader from '../Loader/Loader';
import localStorageService from '../../common/services/localStorage.service';
import DeleteIcon from '@mui/icons-material/Delete';
import FormLimits from '../FormLimits/FormLimits';

const Settings = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);

  const [companyAlias, setCompanyAlias] = useState('');
  const [newCompanyAlias, setNewCompanyAlias] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [logo, setLogo] = useState(null);
  const [newLogo, setNewLogo] = useState(null);
  const [contentType, setContentType] = useState(null);

  // Additional inputs for logo configuration
  const [logoWidth, setLogoWidth] = useState('300px');
  const [logoHeight, setLogoHeight] = useState('250px');
  const [logoPosition, setLogoPosition] = useState('center');
  const [isDisplayForm, setIsDisplayForm] = useState(true);
  const [groups, setGroups] = useState([]); // State to hold form groups

  useEffect(() => {
    const getCompanyAlias = async () => {
      try {
        const response = await apiGatewayService.getCompanyAlias();
        setCompanyAlias(response.data[0].companyAlias);
      } catch (error) {
        console.error('Error getting company alias:', error);
      }
    };

    const getCompanyLogo = async () => {
      try {
        const response = await apiGatewayService.getCompanyLogo();
        console.log("get company logo response: ", response);
        const logoUrl = response.data.url;
        setLogo(logoUrl);
        if(response.data.logoConfig){
          setInitialConfig(response.data.logoConfig);
        }
      } catch (error) {
        console.error('Error getting company logo:', error);
      }
    };


    
    const getFormGroups = async () => {
      try {
        const response = await apiGatewayService.getFormgroups();
        console.log("Get groups response: ", response);
        const dataWithUpdatedKeyAndContent = response.data;
        dataWithUpdatedKeyAndContent.sort((a, b) => {
          if (a.groupName < b.groupName) return -1;
          if (a.groupName > b.groupName) return 1;
          return 0;
        });
        setGroups(dataWithUpdatedKeyAndContent);
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
    };

    const user = localStorageService.getObject('user');
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));

    setIsLoading(true);
    getCompanyAlias();
    getCompanyLogo();
    getFormGroups();
    setIsLoading(false);
  }, []);

  const setInitialConfig = (logoConfig) => {
    setLogoHeight(logoConfig.height);
    setLogoWidth(logoConfig.width);
    setLogoPosition(logoConfig.position);
    setIsDisplayForm(logoConfig.isDisplayForm);
  };

  const handleBackClick = () => {
    setIsEdit(false);
    setIsAddNew(false);
  };

  const handleAliasChange = (e) => {
    setNewCompanyAlias(e.target.value);
  };

  const handleAliasSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const postData = { companyAlias: newCompanyAlias };
      await apiGatewayService.postCompanyAlias(postData);
      setCompanyAlias(newCompanyAlias);
      setNewCompanyAlias('');
    } catch (error) {
      console.error('Error in updating company alias:', error.message);
      alert('Cannot update company alias!');
    }
    setIsLoading(false);
  };

  const handleLogoChange = (e) => {
    if (e?.target?.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      console.log('Selected file:', file);

      const fileType = file.type;
      console.log('File type:', fileType);

      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Remove the prefix if needed
        console.log('Base64 string:', base64String);

        setNewLogo(base64String); // Store the base64 string
        setContentType(fileType);
      };

      reader.onerror = (error) => {
        console.error('Error converting file to base64:', error);
      };

      reader.readAsDataURL(file); // Converts the file to a base64-encoded string
    } else {
      console.log('No file selected or file input is invalid.');
    }
  };

  const handleLogoSubmit = async () => {
    // if (!newLogo) return;
    
    console.log("new logo: ", newLogo);

    const formData = {
      logo: newLogo,
      contentType,
      logoConfig: {
        width: logoWidth,
        height: logoHeight,
        position: logoPosition,
        isDisplayForm
      }
    };

    try {
      setIsLoading(true);
      console.log("form data to submit: ", formData);
      const response = await apiGatewayService.postCompanyLogo(formData);
      console.log("Save logo response: ", response);
      const blob = utilityFunctions.base64ToBlob(newLogo, contentType);
      const blobUrl = URL.createObjectURL(blob);
      if(newLogo!==null){
        setLogo(blobUrl);
      };
      setNewLogo(null);
    } catch (error) {
      console.error('Error uploading company logo:', error);
      alert('Cannot upload company logo!');
    }
    setIsLoading(false);
  };

  const fetchImageAsBase64 = async (url) => {
    debugger;
    const response = await fetch(url, { mode: 'cors' });
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]); // Get the base64 string
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const deleteFormGroup = async (groupId) => {
    try {
      setIsLoading(true);
      await apiGatewayService.deleteFormGroup(groupId);
      setGroups(groups.filter(group => group.groupId !== groupId)); // Remove group from state after deletion
    } catch (error) {
      console.error('Error deleting form group:', error);
      alert('Cannot delete form group!');
    }
    setIsLoading(false);
  };

  return (
    <Container sx={{ padding: '0px' }}>
      <AppBar position="static" sx={{ backgroundColor: 'var(--app-color-secondary)', borderRadius: '10px', padding: '0px', color: 'white' }}>
        <Toolbar>
          <IconButton sx={{ marginLeft: '-20px' }} edge="start" color="inherit" aria-label="menu">
            <SettingsIcon />
          </IconButton>
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            Settings
          </Typography>
          {(isEdit || isAddNew) && (
            <Button
              sx={{
                width: '100px',
                marginRight: '-10px',
                ':hover': {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#e0e0e0',
                  color: 'grey',
                },
              }}
              color="inherit"
              startIcon={<KeyboardBackspaceIcon />}
              onClick={handleBackClick}
              disabled={isOnlyReadAccess}
            >
              Back
            </Button>
          )}
        </Toolbar>
      </AppBar>


      {isLoading && <CircularLoader />}
      { !isLoading &&
        <Box>
          <Box sx={{ padding: 2, backgroundColor: '#f1f2f5', borderRadius: '10px', marginTop: '10px' }}>
            <Typography variant="h6" sx={{ mt: 0, mb: 2, fontWeight: 'bold' }}>
              Basic Info
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                Company Alias: {companyAlias || 'Not Set'}
              </Typography>
              <TextField
                type="text"
                name="companyAlias"
                value={newCompanyAlias}
                onChange={handleAliasChange}
                variant="outlined"
                size="medium"
                sx={{
                  mr: '20px',
                  alignItems: 'flex-start',
                  "& .MuiInputBase-root": {
                    height: 40,
                    backgroundColor: 'white',
                  },
                  "& .MuiOutlinedInput-input": {
                    height: 40,
                    width: '100%',
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: 0,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                }}
              />
              <Button
                disabled={isOnlyReadAccess}
                variant="contained"
                onClick={handleAliasSubmit}
                sx={{
                  height: '40px',
                  backgroundColor: 'var(--button-color)',
                  color: 'white',
                  fontWeight: 'normal',
                  textTransform: 'none',
                  ":hover": {
                    backgroundColor: 'var(--button-hover-color)',
                  },
                }}
              >
                Update
              </Button>
            </Box>
          </Box>

          <Box sx={{ padding: 2, backgroundColor: '#f1f2f5', borderRadius: '10px', marginTop: '20px' }}>
            <Typography variant="h6" sx={{ mt: 0, fontWeight: 'bold' }}>
              Company Logo
            </Typography>
            {isLoading ? (
              <CircularProgress />
            ) : logo ? (
              <Box
                component="img"
                src={logo}
                alt="Company Logo"
                sx={{ alignItems: 'center', backgroundColor: 'white', mt: 1, mr: 0, mb: 3, borderRadius: '5px', maxHeight: '250px', maxWidth: '300px' }}
              />
            ) : (
              <Typography variant="body1" sx={{ flexGrow: 1, mb: 2 }}>
                Logo not updated
              </Typography>
            )}

            {/* Additional Inputs for Logo Configuration */}
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <TextField
                label="Width"
                type="text"
                value={logoWidth}
                onChange={(e) => setLogoWidth(e.target.value)}
                variant="outlined"
                size="medium"
                sx={{
                  flex: 1, // This makes the TextField take equal width
                  "& .MuiInputBase-root": {
                    height: 40,
                    backgroundColor: 'white'
                  },
                  "& .MuiOutlinedInput-input": {
                    height: 40,
                    width: '100%',
                  },
                }}
              />
              <TextField
                label="Height"
                type="text"
                value={logoHeight}
                onChange={(e) => setLogoHeight(e.target.value)}
                variant="outlined"
                size="medium"
                sx={{
                  flex: 1, // This makes the TextField take equal width
                  "& .MuiInputBase-root": {
                    height: 40,
                    backgroundColor: 'white'
                  },
                  "& .MuiOutlinedInput-input": {
                    height: 40,
                    width: '100%',
                  },
                }}
              />
              <FormControl variant="outlined" sx={{ flex: 1 }}>
                <InputLabel>Position</InputLabel>
                <Select
                  value={logoPosition}
                  onChange={(e) => setLogoPosition(e.target.value)}
                  label="Position"
                  sx={{ height: '40px', backgroundColor: 'white' }}
                >
                  <MenuItem value="left">Left</MenuItem>
                  <MenuItem value="center">Center</MenuItem>
                  <MenuItem value="right">Right</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" sx={{ flex: 1 }}>
                <InputLabel>Display</InputLabel>
                <Select
                  value={isDisplayForm}
                  onChange={(e) => setIsDisplayForm(e.target.value === 'true')}
                  label="Display Form"
                  sx={{ height: '40px', backgroundColor: 'white' }}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </FormControl>

            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 1 }}>
              <TextField
                type="file"
                name="companyLogo"
                onChange={handleLogoChange}
                variant="outlined"
                size="medium"
                sx={{
                  flexGrow: 1,
                  backgroundColor: 'white',
                  "& .MuiInputBase-root": {
                    backgroundColor: 'white',
                    height: 45,
                  },
                  "& .MuiOutlinedInput-root": {
                    padding: 0,
                    height: 45,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                }}
              />
              <Button
                disabled={isOnlyReadAccess}
                variant="contained"
                onClick={handleLogoSubmit}
                sx={{
                  height: '45px',
                  marginLeft: '20px',
                  backgroundColor: 'var(--button-color)',
                  color: 'white',
                  fontWeight: 'normal',
                  textTransform: 'none',
                  ":hover": {
                    backgroundColor: 'var(--button-hover-color)',
                  },
                }}
              >
                Update
              </Button>
            </Box>

          </Box>
          <Box sx={{ padding: 2, backgroundColor: '#f1f2f5', borderRadius: '10px', marginTop: '20px' }}>
            <Typography variant="h6" sx={{ mt: 0, mb: 1, fontWeight: 'bold' }}>
              Form Limits
            </Typography>
            <FormLimits isSettings={true}/>
          </Box>
 
          <Box sx={{ padding: 2, backgroundColor: '#f1f2f5', borderRadius: '10px', marginTop: '20px' }}>
            <Typography variant="h6" sx={{ mt: 0, mb: 1, fontWeight: 'bold' }}>
              Form Groups
            </Typography>
            <Grid container spacing={2}>
              {groups.map((group) => (
                <Grid item xs={12} sm={6} md={3} key={group.id}>
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ padding: '5px', backgroundColor: 'white', borderRadius: '5px' }}>
                    <Typography variant="body1">{group.groupName}</Typography>
                    <IconButton sx={{ color: '#CC3824' }}  onClick={() => deleteFormGroup(group.groupId)} disabled={isOnlyReadAccess}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      }
    </Container>
  );
};

export default Settings;