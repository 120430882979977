import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Typography, Container, Toolbar, Paper, Tooltip, TextField, InputAdornment, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ArticleIcon from '@mui/icons-material/Article';
import PublishIcon from '@mui/icons-material/Publish';
// import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SearchIcon from '@mui/icons-material/Search';
import WorkspacesIcon from '@mui/icons-material/Workspaces';

// import FormsModal from '../FormsModal/FormsModal';
import apiGatewayService from '../../common/services/apiGatewayService';
import localStorageService from '../../common/services/localStorage.service';
import utilityFunctions from '../../common/services/utilityFunctions';
import CircularLoader from '../Loader/Loader';
// import PublishFormsModal from '../PublishFormsModal/PublishFormsModal';
// import FormSectionsBuilder from '../FormBuilder/FormSectionsBuilder/FormSectionsBuilder';
// import AddGroupModal from '../AddGroupModal/AddGroupModal';
// import FormGroupSelector from '../FormGroupSelector/FormGroupSelector';
import PostAddIcon from '@mui/icons-material/PostAdd';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FormsSubmitted from '../FormsSubmitted/FormsSubmitted';

const Forms = (props) => {
  const [forms, setForms] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isViewJSON, setIsViewJSON] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewIndex, setIsViewIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [cogntioUser, setCogntioUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOnlyReadAccess, setIsOnlyReadAccess] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [filterFormName, setFilterFormName] = useState('');
  const [filterLastEditedOn, setFilterLastEditedOn] = useState('');
  const [filterLastEditedBy, setFilterLastEditedBy] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterCreatedBy, setFilterCreatedBy] = useState(''); // New filter for createdBy
  const [filterGroupName, setFilterGroupName] = useState(''); // New filter for groupName
  const [currentForm, setCurrentForm] = useState(null);
  const [companyAlias, setCompanyAlias] = useState('');
  const [isAddGroup, setIsAddGroup] = useState(false); // New state for add group modal
  const [isFormGroupSelectorOpen, setIsFormGroupSelectorOpen] = useState(false); // New state for FormGroupSelector modal
  const [isViewSubmittedForm, setIsViewSubmittedForm] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = async () => {
    setAllFalse();
    setOpen(false);
    setIsPublish(false);
    await getAllForms();
  }

  const handleCloseWithGetAllForms = async () => {
    setAllFalse();
    setOpen(false);
    setIsPublish(false);
    await getAllForms();
  }

  useEffect(() => {
    const getCompanyAlias = async () => {
      try {
        const response = await apiGatewayService.getCompanyAlias();
        setCompanyAlias(response.data[0].companyAlias);
      } catch (error) {
        console.error('Error getting company alias:', error);
      }
    };
    const getForms = async (user) => {
      try {
        const response = await apiGatewayService.getFormsData();
        const dataWithUpdatedKeyAndContent = response.data;
        dataWithUpdatedKeyAndContent.sort((a, b) => {
          if (a.formName < b.formName) return -1;
          if (a.formName > b.formName) return 1;
          return 0;
        });
        setForms(dataWithUpdatedKeyAndContent);
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
      setIsLoading(false);
    };

    const getFormsByGroup = async () => {
      try {
        const response = await apiGatewayService.getFormsByGroup(props.selectedGroupId);
        console.log("Get forms by group response: ", response);
        const dataWithUpdatedKeyAndContent = response.data;
        dataWithUpdatedKeyAndContent.sort((a, b) => {
          if (a.formName < b.formName) return -1;
          if (a.formName > b.formName) return 1;
          return 0;
        });
        setForms(dataWithUpdatedKeyAndContent);
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
      setIsLoading(false);
    };


    const user = localStorageService.getObject('user');
    setCogntioUser(user);
    setIsOnlyReadAccess(utilityFunctions.checkIfOnlyReadAccess(user));
    if (props.selectedGroupId!==''){
      getFormsByGroup();
    } else {
      getForms();
    }
    getCompanyAlias();
  }, [props.selectedGroupId]);

  const getAllForms = async () => {
    try {
      setIsLoading(true);
      const response = await apiGatewayService.getFormsData();
      const dataWithUpdatedKeyAndContent = response.data;
      dataWithUpdatedKeyAndContent.sort((a, b) => {
        if (a.formName < b.formName) return -1;
        if (a.formName > b.formName) return 1;
        return 0;
      });
      setForms(dataWithUpdatedKeyAndContent); 
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching forms:', error);
    }
  };

  const setAllFalse = () => {
    setIsAddNew(false);
    setIsViewJSON(false);
    setIsView(false);
    setIsEdit(false);
    setIsViewIndex(null);
    setIsPublish(false);
    setIsAddGroup(false); // Reset isAddGroup when closing modals
    setIsViewSubmittedForm(false);
  };

  const handleAddNew = () => {
    setAllFalse();
    setIsAddNew(true);
    handleOpen();
  };

  const handleAddGroup = () => {
    setAllFalse();
    setIsAddGroup(true); // Open add group modal
  };

  const handleViewJSON = async (index) => {
    setAllFalse();
    await getFormById(forms[index].formId);
    setTimeout(() => {
      setIsViewJSON(true);
      setIsLoading(false);
    }, 300);
    setIsViewIndex(index);
    handleOpen();
  };

  const handleView = async (index) => {
    setAllFalse();
    setIsView(true);
    localStorageService.setObject('isCurrForm', 'true');
    const newTab = window.open(`/displayForm/${companyAlias}/${filteredForms[index].formId}`, '_blank', 'noopener,noreferrer');
    if (newTab) newTab.opener = null;
  };

  const handleViewSubmittedForm = (index) => {
    setAllFalse();
    setIsViewSubmittedForm(true);
    setIsViewIndex(index);
  };

  const handleCloseFormsSubmittedView = () => {
    setAllFalse();
    setIsViewSubmittedForm(false);
  };

  const handleEdit = async (index) => {
    setAllFalse();
    await getFormById(forms[index].formId);
    setTimeout(() => {
      setIsEdit(true);
      setIsLoading(false);
    }, 300);
    setIsViewIndex(index);
    handleOpen();
  };

  const handleDelete = async (index) => {
    try {
      const postData = {
        formNames: [forms[index].formId],
      };
      await apiGatewayService.deleteFormsData(postData);
      setAllFalse();
      const formsNew = forms.filter((_, index2) => index2 !== index);
      setForms(formsNew);
    } catch (error) {
      console.error("Error: ", error);
      alert('Form cannot be deleted');
    }
  };

  const handlePublishForm = async (index) => {
    setAllFalse();
    setIsViewIndex(index);
    setIsPublish(true);
  };

  const submitPublishForms = async (check) => {
    if (check !== 'cancel') {
      try {
        const postData = {
          formNames: [{ 
              formId: decodeURIComponent(forms[viewIndex].formId), 
              formName: decodeURIComponent(forms[viewIndex].formName), 
              route: check, 
              lastEditedOn: formatDate(new Date()), 
              createdBy: forms[viewIndex].createdBy }],
        };
        await apiGatewayService.postPublishFormsData(postData);
        setAllFalse();
      } catch (error) {
        console.error("Error: ", error);
        alert('Form cannot be published.');
      }
    }
    setAllFalse();
    handleCloseWithGetAllForms();
  };

  function formatDate(date) {
    const pad = (number, length = 2) => String(number).padStart(length, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = pad(date.getMilliseconds(), 3) + '000000';
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  const getFormById = async (formId) => {
    setIsLoading(true);
    try {
      const response = await apiGatewayService.getFormById(formId);
      setCurrentForm(response.data);
      return response;
    } catch (error) {
      console.error('Error getting form by id: ', error);
    }
    setIsLoading(false);
  }

  const filteredForms = forms.filter(form => {
    const formNameMatches = !filterFormName || form.formName.toLowerCase().includes(filterFormName.toLowerCase());
    const lastEditedOnMatches = !filterLastEditedOn || form.lastEditedOn?.toLowerCase().includes(filterLastEditedOn.toLowerCase());
    const lastEditedByMatches = !filterLastEditedBy || form.lastEditedBy.toLowerCase().includes(filterLastEditedBy.toLowerCase());
    const statusMatches = !filterStatus || form.route.toLowerCase().includes(filterStatus.toLowerCase());
    const createdByMatches = !filterCreatedBy || form.createdBy?.toLowerCase().includes(filterCreatedBy.toLowerCase()); // New filter logic for createdBy
    const groupNameMatches = !filterGroupName || form.groupName?.toLowerCase().includes(filterGroupName.toLowerCase()); // New filter logic for groupName
    return formNameMatches && lastEditedOnMatches && lastEditedByMatches && statusMatches && createdByMatches && groupNameMatches;
  });

  const handleGroupSubmit = async (groupName) => {
    console.log('Group Name:', groupName);
    try {
      const response = await apiGatewayService.postFormGroups({groupName});
      console.log("Post form group response: ", response);
    } catch(error){
      console.log('Error in posting form group: ', error);
    }
    setIsAddGroup(false); 
  };

  
  const handleAssignGroup = (index) => {
    setAllFalse();
    setIsViewIndex(index);
    setIsFormGroupSelectorOpen(true); // Open FormGroupSelector modal
  };

  const handleFormGroupSubmit = async (groupId) => {
    console.log("Group name to submit for form: ", groupId);
    if (viewIndex !== null) {
      const selectedForm = forms[viewIndex];  
      if (selectedForm) {
        try {
          const group = {
            groupId: groupId,
          }
          await apiGatewayService.assignFormGroup(selectedForm.formId, group);
          setIsFormGroupSelectorOpen(false);
          await props.updateFormGroups();
          handleCloseWithGetAllForms();
        } catch (error) {
          console.error('Error submitting group for form:', error);
        }
      }
    }
  };

  return (
    <Container sx={{ padding: '0px' }}>
      { (!isViewSubmittedForm) && (<Toolbar sx={{ backgroundColor: 'var(--app-color-secondary)', borderRadius: "10px", padding: '0px', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton sx={{ marginLeft: '-20px' }} edge="start" color="inherit" aria-label="menu">
            <ArticleIcon />
          </IconButton>
          <Typography variant="h5" sx={{ mr: 2 }}>
            Forms List
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {(!isEdit && !isAddNew && !isViewSubmittedForm) && (
            <>
              {/* <Button sx={{
                marginRight: '10px',
                ":hover": {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#e0e0e0',
                  color: 'grey',
                },
              }} color="inherit" startIcon={<AddIcon />} onClick={handleAddNew} disabled={isOnlyReadAccess}>
                Add New
              </Button>
              <Button sx={{
                marginRight: '10px',
                ":hover": {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#e0e0e0',
                  color: 'grey',
                },
              }} color="inherit" startIcon={<AddIcon />} onClick={handleAddGroup} disabled={isOnlyReadAccess}>
                Add Group
              </Button>
              <Button sx={{
                marginRight: '-10px',
                ":hover": {
                  backgroundColor: '#F0F4F4',
                  color: '#2b4252',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#e0e0e0',
                  color: 'grey',
                },
              }} color="inherit" startIcon={<ImportExportIcon />} onClick={() => alert("This feature is in progress.")} disabled={isOnlyReadAccess}>
                Import
              </Button> */}
            </>
          )}
          {(isEdit || isAddNew || isViewSubmittedForm) && 
            <Button sx={{
              width: '100px',
              marginRight: '-10px',
              ":hover": {
                backgroundColor: '#F0F4F4',
                color: '#2b4252',
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: 'grey',
              },
            }} color="inherit" startIcon={<KeyboardBackspaceIcon />} onClick={handleClose} disabled={isOnlyReadAccess}>
              Back
            </Button>
          }
        </Box>
      </Toolbar>)}

      {isLoading && <CircularLoader />}
      {(!isLoading && !isEdit && !isAddNew && !isViewSubmittedForm) &&
        <TableContainer component={Paper} sx = {{mt: '5px'}}>
          <Table>
            <TableHead>
              <TableRow sx = {{height: '40px'}}>
                <TableCell>
                  <TextField
                    variant="outlined"
                    placeholder="Form Name"
                    value={filterFormName}
                    onChange={(e) => setFilterFormName(e.target.value)}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    placeholder="Created By"
                    value={filterCreatedBy}
                    onChange={(e) => setFilterCreatedBy(e.target.value)}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    placeholder="Last Edited By"
                    value={filterLastEditedBy}
                    onChange={(e) => setFilterLastEditedBy(e.target.value)}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    placeholder="Last Edited On"
                    value={filterLastEditedOn}
                    onChange={(e) => setFilterLastEditedOn(e.target.value)}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    placeholder="Status"
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    placeholder="Group Name"
                    value={filterGroupName}
                    onChange={(e) => setFilterGroupName(e.target.value)}
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: '100%',
                      "& .MuiInputBase-root": {
                        height: 35,
                      },
                      "& .MuiOutlinedInput-input": {
                        height: 35,
                        width: '100%',
                      },
                    }}
                  />
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredForms.map((form, index) => (
                <TableRow key={form.formId}  sx = {{height: '40px'}}>
                  <TableCell>{form.formName}</TableCell>
                  <TableCell>{form?.createdBy || ''}</TableCell>
                  <TableCell>{form?.lastEditedBy || ''}</TableCell>
                  <TableCell>{form?.lastEditedOn?.split(' ')[0] || ''}</TableCell>
                  <TableCell>{form.route}</TableCell>
                  <TableCell>{form.groupName || ''}</TableCell> {/* New column for groupName */}
                  <TableCell sx = {{p: 0, pt: '5px', pb: '5px '}}>
                    {/* <Tooltip title="Publish Forms">
                      <IconButton sx={{ color: '#2b4252' }} aria-label="view" onClick={() => handlePublishForm(index)}>
                        <PublishIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="View JSON">
                      <IconButton sx={{ color: '#2b4252' }} aria-label="view" onClick={() => handleViewJSON(index)}>
                        <DataObjectIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="View">
                      <IconButton aria-label="json" onClick={() => handleView(index)}>
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton sx={{ color: '#2b4252' }} aria-label="edit" onClick={() => handleEdit(index)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Assign Group">
                      <IconButton sx={{ color: '#2b4252' }} aria-label="assign-group" onClick={() => handleAssignGroup(index)} disabled={isOnlyReadAccess}>
                        <WorkspacesIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton sx={{ color: '#CC3824' }} aria-label="delete" onClick={() => handleDelete(index)} disabled={isOnlyReadAccess}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip> */}
                  <Tooltip  title="Add Form">
                  <IconButton aria-label="json" onClick={() => handleView(index)}>
                    <PostAddIcon sx = {{fontSize: '35px'}} />
                  </IconButton>
                  </Tooltip>
                  <Tooltip  title="Submitted Forms">
                  <IconButton aria-label="json" onClick={() => handleViewSubmittedForm(index)}>
                    <FolderOpenIcon sx = {{fontSize: '35px'}} />
                  </IconButton>
                  </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }

      {/* {isAddNew && <FormSectionsBuilder form={{}} handleClose={handleClose} isNew={true} />}
      {isEdit && <FormSectionsBuilder form={currentForm} handleClose={handleClose} isEditInput = {true} formIdInput = {filteredForms[viewIndex].formId || null} />}
      {isViewJSON && <FormsModal open={open} handleClose={handleClose} form={currentForm} isViewJSON={true} />}
      {isPublish && 
        <PublishFormsModal
          handleClose={handleClose}
          open={isPublish}
          submitPublishForms={submitPublishForms}
        />
      }
      {
        isAddGroup && <AddGroupModal
        open = {isAddGroup}
        handleClose = {handleClose}
        handleSubmit= {handleGroupSubmit}
        />
      } */}
      {/* {
        isFormGroupSelectorOpen && <FormGroupSelector
          isOpen={isFormGroupSelectorOpen}
          handleClose={() => setIsFormGroupSelectorOpen(false)}
          submitGroup={handleFormGroupSubmit}
        />
      } */}
        {
        isViewSubmittedForm && 
        <FormsSubmitted 
        formId = {forms[viewIndex].formId}
        handleCloseFormsSubmittedView = {handleCloseFormsSubmittedView}
      />
      }
    </Container>
  );
};

export default Forms;