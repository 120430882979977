import React, { useEffect, useState, useRef } from 'react';
import classes from './Section.module.css';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button,
  TextField, IconButton, Checkbox, FormGroup, FormControlLabel, Radio
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from 'react-select';
import SignatureCanvas from 'react-signature-canvas';
import { TimePicker, DatePicker } from 'antd';
import 'antd/dist/antd.css'; // Import Ant Design styles
import moment from 'moment';
import './CustomTimePicker.css';

const Section = (props) => {
  const [tableData, setTableData] = useState({});
  const sigCanvas = useRef({});

  useEffect(() => {
    console.log("Updated Props: ", props);
  }, [props]);

  const checkIfSectionDisabled = (field, fieldKey) => {
    let selectionDisabled = true;
    if (props.dropdownOptions[fieldKey] !== undefined) {
      if ((field.type === 'selection' || field.type === 'dropdown') && props.dropdownOptions[fieldKey].length > 1) {
        selectionDisabled = false;
      }
      if ((field.type === 'selection' || field.type === 'dropdown') && props.dropdownOptions[fieldKey][0].label !== '' && props.dropdownOptions[fieldKey][0].value !== '') {
        selectionDisabled = false;
      }
    }
    return selectionDisabled;
  }

  const handleAddTableRow = (fieldKey) => {
    const newRow = fieldKey in tableData ? [...tableData[fieldKey], {}] : [{}];
    setTableData(prev => ({ ...prev, [fieldKey]: newRow }));
  }

  const handleRemoveTableRow = (fieldKey, rowIndex) => {
    const updatedRows = tableData[fieldKey].filter((_, index) => index !== rowIndex);
    setTableData(prev => ({ ...prev, [fieldKey]: updatedRows }));
    props.handleNativeInputChangeTableData({ target: { name: fieldKey, value: updatedRows } }, props.sectionIndex);
  }

  const handleTableInputChange = (fieldKey, rowIndex, columnKey, value, fieldName) => {
    const updatedRows = tableData[fieldKey].map((row, index) =>
      index === rowIndex ? { ...row, [columnKey]: value } : row
    );
    setTableData(prev => ({ ...prev, [fieldKey]: updatedRows }));
    props.handleNativeInputChangeTableData({ target: { name: fieldName, value: updatedRows } }, props.sectionIndex);
  }

  const handleSubmit = (fieldKey) => {
    props.handleNativeInputChange({ target: { name: fieldKey, value: tableData[fieldKey] } }, props.sectionIndex);
  }

  const clearSignature = (fieldKey, fieldName, required) => {
    sigCanvas.current[fieldKey].clear();
    props.handleNativeInputChangeSignatureData({ target: { name: fieldName, value: '' } }, props.sectionIndex, required);
  }

  const handleCheckboxChange = (fieldName, name, checked, required) => {
    // const updatedValues = { ...props.fieldValues[fieldKey], [name]: checked };
    const updatedValues =   checked ? { [name]: checked}: '' ;
    console.log("updatedValues: ", updatedValues);
    props.handleNativeInputChangeAdditionalData({ target: { name: fieldName, value: updatedValues } }, props.sectionIndex, required);
  };

  const renderTableCell = (column, rowIndex, fieldKey, row, fieldName) => {
    switch (column.type) {
      case 'time':
        return (
          <TableCell key={column.name} sx={{ border: '1px solid #ccc', height: '40px', padding: '0', margin: 0 }}>
            <TimePicker
              style={{
                border: 'none',
                height: '40px',
                width: '100%',
                fontFamily: 'Roboto, sans-serif',
                padding: '0 12px',
                backgroundColor: 'white',
                borderRadius: '4px',
                outline: 'none',
                cursor: 'pointer',
              }}
              value={row[column.name] ? moment(row[column.name], 'h:mm a') : null}
              onChange={(time, timeString) => handleTableInputChange(fieldKey, rowIndex, column.name, timeString, fieldName)}
              format="h:mm a"
              allowClear={false}
              showNow={false}
              renderExtraFooter={null}
              use12Hours
              onSelect={(time) => handleTableInputChange(fieldKey, rowIndex, column.name, time.format('h:mm a'), fieldName)}
            />
          </TableCell>
        );
      case 'date':
        return (
          <TableCell key={column.name} sx={{ border: '1px solid #ccc', height: '40px', padding: '0' }}>
            <DatePicker
              style={{
                height: '40px',
                border: 'none',
                fontFamily: 'Roboto, sans-serif',
                padding: '0 12px',
                backgroundColor: 'white',
                borderRadius: '4px',
                outline: 'none',
                cursor: 'pointer',
                width: '100%',
              }}
              value={row[column.name] ? moment(row[column.name], 'YYYY-MM-DD') : null}
              onChange={(date, dateString) => handleTableInputChange(fieldKey, rowIndex, column.name, dateString, fieldName)}
              format="YYYY-MM-DD"
            />
          </TableCell>
        );
      case 'number':
        return (
          <TableCell key={column.name} sx={{ border: '1px solid #ccc', height: '40px', padding: '0' }}>
            <TextField
              type="number"
              value={row[column.name] || ''}
              onChange={(e) => handleTableInputChange(fieldKey, rowIndex, column.name, e.target.value, fieldName)}
              fullWidth
              sx={{
                width: '100%',
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: 'white',
                  border: 'none',
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: '100%',
                  border: 'none',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: 'none',
                },
              }}
            />
          </TableCell>
        );
      case 'text':
      default:
        return (
          <TableCell key={column.name} sx={{ border: '1px solid #ccc', height: '40px', padding: '0' }}>
            <TextField
              value={row[column.name] || ''}
              onChange={(e) => handleTableInputChange(fieldKey, rowIndex, column.name, e.target.value, fieldName)}
              fullWidth
              sx={{
                width: '100%',
                "& .MuiInputBase-root": {
                  height: 40,
                  backgroundColor: 'white',
                  border: 'none',
                },
                "& .MuiOutlinedInput-input": {
                  height: 40,
                  width: '100%',
                  border: 'none',
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: 'none',
                },
              }}
            />
          </TableCell>
        );
    }
  }

  return (
    <div key={props.sectionIndex} id={`section-${props.sectionIndex}`} className={classes.FormSection}>
      <h2 className={classes.FormSectionTitle}>{props.section.title}</h2>
      {props.section.fields.map(field => {
        const fieldKey = `${props.sectionIndex}-${field.name}`;
        const selectedOption = props.dropdownOptions[fieldKey]?.find(option => String(option.value).trim() === String(props.fieldValues[fieldKey]).trim());
        let selectionDisabled = checkIfSectionDisabled(field, fieldKey);

        return (
          <React.Fragment key={field.name}>
            <div className={classes.FormFieldRow} style={{ display: props.determineVisibility(field, props.sectionIndex) ? 'flex' : 'none', alignItems: 'center' }}>
              <label className={classes.FormLabel}>
                {field.label}
                {field.required && <span className={classes.Mandatory}>*</span>}
              </label>
              <div className={(field.type === 'checkbox') ? classes.InputContainerCheckbox : classes.InputContainer}>
                {field.type === 'textarea' ? (
                  <textarea
                    name={field.name}
                    required={field.required}
                    maxLength={field.maxLength}
                    placeholder={field.placeholder}
                    onChange={(e) => props.handleNativeInputChange(e, props.sectionIndex)}
                    value={props.fieldValues[fieldKey] || ''}
                    className={classes.FormField} />
                ) : field.type === 'selection' ? (
                  props.dropdownOptions[fieldKey] && props.dropdownOptions[fieldKey].length > 0 ? (
                    <Select
                      options={props.dropdownOptions[fieldKey]}
                      onChange={(selectedOption) => props.handleSelectChange(selectedOption, props.sectionIndex, field.name)}
                      value={props.dropdownOptions[fieldKey].find(option => option.value === props.fieldValues[fieldKey])}
                      isDisabled={selectionDisabled}
                      className={classes.FormField}
                      styles={props.customStyles}
                      placeholder={field.placeholdertext || 'Select...'}
                    />
                  ) : (
                    <p>Loading options...</p>
                  )
                ) : field.type === 'dropdown' ? (
                  <select
                    name={field.name}
                    onChange={(e) => props.handleNativeInputChange(e, props.sectionIndex)}
                    value={props.fieldValues[fieldKey] || ''}
                    className={classes.FormField}
                    disabled={selectionDisabled}
                  >
                    {props.dropdownOptions[fieldKey]?.map(option => (
                      <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                  </select>
                ) : field.type === 'date' ? (
                  <input
                    type={field.type}
                    name={field.name}
                    maxLength={field.length}
                    required={field.required}
                    placeholder={field.placeholdertext}
                    onChange={(e) => props.handleNativeInputChange(e, props.sectionIndex)}
                    value={props.fieldValues[fieldKey] || ''}
                    className={classes.FormFieldDate} />
                ) : field.type === 'time' ? (
                  <TimePicker
                    style={{
                      height: '40px',
                      width: '98%',
                      fontFamily: 'Roboto, sans-serif',
                      padding: '0 12px',
                      backgroundColor: 'white',
                      borderRadius: '4px',
                      outline: 'none',
                      cursor: 'pointer',
                    }}
                    showNow={false}
                    value={props.fieldValues[fieldKey] ? moment(props.fieldValues[fieldKey], 'h:mm a') : null}
                    onChange={(time, timeString) => {
                      const value = time ? timeString : '';  // Handle clear case
                      props.handleNativeInputChangeAdditionalData({ target: { name: field.name, value } }, props.sectionIndex, field.required);
                    }}
                    onSelect={(time) =>
                      props.handleNativeInputChangeAdditionalData({ target: { name: field.name, value: time.format('h:mm a') } }, props.sectionIndex, field.required)
                    }
                    format="h:mm a"
                    allowClear={true}
                  />
                ) : field.type === 'checkbox' ? (
                  <input
                    type={field.type}
                    name={field.name}
                    maxLength={field.length}
                    required={field.required}
                    placeholder={field.placeholdertext}
                    onChange={(e) => props.handleNativeInputChange(e, props.sectionIndex)}
                    value={props.fieldValues[fieldKey] || ''}
                    className={classes.FormFieldCheckbox} />
                ) : field.type === 'table' ? (
                  <TableContainer component={Paper} className={classes.TableContainer} name={field.name}>
                    <Table aria-label="dynamic table">
                      <TableHead>
                        <TableRow>
                          {field.tableColumns.map((column, index) => (
                            <TableCell
                              key={index}
                              sx={{
                                border: '1px solid #ccc',
                                backgroundColor: '#f7f7f7',
                                textAlign: 'center',
                                height: '40px',
                                padding: '0',
                              }}
                            >
                              {column.name}
                            </TableCell>
                          ))}
                          <TableCell
                            sx={{
                              border: '1px solid #ccc',
                              backgroundColor: '#f7f7f7',
                              textAlign: 'center',
                              height: '40px',
                              padding: '0',
                            }}
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData[fieldKey]?.map((row, rowIndex) => (
                          <TableRow key={rowIndex}>
                            {field.tableColumns.map((column, colIndex) =>
                              renderTableCell(column, rowIndex, fieldKey, row, field.name)
                            )}
                            <TableCell
                              sx={{
                                border: '1px solid #ccc',
                                height: '40px',
                                padding: 0,
                                textAlign: 'center',
                              }}
                            >
                              <IconButton
                                onClick={() => handleRemoveTableRow(fieldKey, rowIndex)}
                                sx={{
                                  height: '40px',
                                  width: '40px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell
                            colSpan={field.tableColumns.length + 1}
                            sx={{
                              border: '1px solid #ccc',
                              textAlign: 'center',
                              height: '40px',
                              padding: 0,
                            }}
                          >
                            <IconButton
                              onClick={() => handleAddTableRow(fieldKey)}
                              sx={{
                                height: '40px',
                                width: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : field.type === 'signature' ? (
                  <div className={classes.SignatureContainer}>
                    <SignatureCanvas
                      id={fieldKey}
                      penColor="black"
                      canvasProps={{ className: classes.SignatureCanvas }}
                      ref={(ref) => sigCanvas.current[fieldKey] = ref}
                      onEnd={() => {
                        props.handleNativeInputChangeSignatureData({
                          target: {
                            name: field.name,
                            value: sigCanvas.current[fieldKey].getTrimmedCanvas().toDataURL('image/png')
                          }
                        }, props.sectionIndex, field.required);
                      }}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => clearSignature(fieldKey, field.name, field.required)}
                      sx={{ mt: 1, width: '100px' }}
                    >
                      Clear
                    </Button>
                  </div>
                ) : field.type === 'multiCheckbox' ? (
                        <FormGroup row>
                            {field?.multiCheckBoxInputs.map((input, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Radio
                                            checked={props?.fieldValues?.[fieldKey][input?.name] || false}
                                            onChange={(e) => handleCheckboxChange(field.name, input.name, e.target.checked, field.required)}
                                            sx={{
                                                ml: '2px',
                                                '& .MuiSvgIcon-root': {
                                                    borderRadius: '50%', // Ensure the SVG is round (default for Radio)
                                                    width: '24px', // Adjust the width if needed
                                                    height: '24px', // Adjust the height if needed
                                                },
                                                '&.Mui-checked': {
                                                    color: 'primary.main', // Customize the color when checked
                                                },
                                                '& .MuiRadio-root': {
                                                    padding: '8px', // Ensure padding doesn't affect the shape
                                                },
                                            }}
                                        />
                                    }
                                    label={input?.name}
                                    sx={{
                                        marginRight: 3, // Space between each radio button
                                    }}
                                />
                            ))}
                        </FormGroup>

                ) : (
                  <input
                    type={field.type}
                    name={field.name}
                    maxLength={field.length}
                    required={field.required}
                    placeholder={field.placeholdertext}
                    onChange={(e) => props.handleNativeInputChange(e, props.sectionIndex)}
                    value={props.fieldValues[fieldKey] || ''}
                    className={classes.FormField} />
                )}
              </div>
            </div>
            <div className={classes.FormFieldRow}>
              <label className="form-label"> </label>
              {props.errors[fieldKey] && <div className="error-message">{props.errors[fieldKey]}</div>}
            </div>
            <div className={classes.FormFieldRow}>
              <label className="form-label"> </label>
              <div className="input-container">
                {selectedOption && selectedOption.additionalText && (
                  <p className="additional-text">{selectedOption.additionalText}</p>
                )}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default Section;